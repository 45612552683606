export const getDateString = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const monthNumber = date.getMonth();
  const month = (monthNumber + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const getTimeString = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getDateTimeString = (date: Date): string => {
  const dateString = getDateString(date);
  const timeString = getTimeString(date);
  return `${dateString} ${timeString}`;
};
