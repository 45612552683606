import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit, MdCheck, MdClose } from 'react-icons/md';
import rbacRules from '../../constants/rbacRules';
import { IRoleObject } from '../../interfaces/serviceResponses/IRolesResponse';
import { ISystemUserObject } from '../../interfaces/serviceResponses/IUserResponse';
import { getEmailLocalUser } from '../../services/localStorage';
import { checkPermission } from '../../services/rbac';
import { getRoles } from '../../services/roles';
import { getSystemUsersList, patchSystemUser } from '../../services/users';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalImportUsers from './ModalImportUsers';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const Users = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [users, setUsers] = useState<ISystemUserObject[]>([]);
  const [roles, setRoles] = useState<IRoleObject[]>([]);
  const [deletingUserId, setDeletingUserId] = useState('-1');
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(true);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [editingRoleValue, setEditingRoleValue] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false);

  useEffect(() => {
    const requestRoles = async () => {
      try {
        const response = await getRoles();
        const roles = response.data;
        setRoles(roles);
      } catch (error) {
        console.log(error);
      }
    };
    const requestUsers = async () => {
      try {
        const response = await getSystemUsersList();
        setUsers(response.data);
        setIsSubmiting(false);
      } catch (error) {
        console.log(error);
        setIsSubmiting(false);
      }
    };

    if (isSubmiting) {
      requestRoles();
      requestUsers();
    }
  }, [isSubmiting]);

  const editUser = (key: number, role: string) => {
    setEditingRowIndex(key);
    setEditingRoleValue(role);
    setIsEditing(true);
  };

  const submitEditUser = (key: number) => {
    const requestUsers = async () => {
      try {
        await patchSystemUser(users[key].id, editingRoleValue);
        props.showSnackBar(translate('notification/success_save'), 'success');
        setIsSubmiting(true);
      } catch (error) {
        console.log(error);
      }
    };
    requestUsers();
    cancelEditUser();
  };

  const cancelEditUser = () => {
    setIsEditing(false);
    setEditingRowIndex(-1);
    setEditingRoleValue('');
  };

  const deleteUser = (id: string) => {
    setDeletingUserId(id);
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = (deleted: boolean) => {
    setShowDeleteModal(false);
    setIsSubmiting(deleted);
  };

  const onCloseImportModal = (deleted: boolean) => {
    setShowModalImport(false);
    setIsSubmiting(deleted);
  };

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setEditingRoleValue(value);
  };

  const renderUsers = () => {
    if (users) {
      return users.map((userInfo, key) => {
        const { id, name, email, role_id } = userInfo;
        const role = roles.find(role => role.role_id === role_id);
        const roleName = role ? role.name : '';
        let custom_class = '';
        let role_html = <td>{roleName}</td>;
        let buttons_html = (
          <td>
            <button
              type="button"
              onClick={() => editUser(key, role_id)}
              disabled={!checkPermission(rbacRules.user_edit.scopes)}>
              <MdEdit size={16} />
            </button>
            <button
              type="button"
              onClick={() => deleteUser(id)}
              disabled={getEmailLocalUser() === email || !checkPermission(rbacRules.user_delete.scopes)}>
              <MdDelete size={16} />
            </button>
          </td>
        );

        if (isEditing && editingRowIndex === key) {
          custom_class = 'row_editing';
          role_html = (
            <td>
              <select value={editingRoleValue} onChange={onChange}>
                {roles.map(role => {
                  return (
                    <option key={role.role_id} value={role.role_id}>
                      {role.name}
                    </option>
                  );
                })}
              </select>
            </td>
          );

          buttons_html = (
            <td>
              <button type="button" onClick={() => submitEditUser(key)}>
                <MdCheck size={16} />
              </button>
              <button type="button" onClick={cancelEditUser}>
                <MdClose size={16} />
              </button>
            </td>
          );
        }

        return (
          <tr key={`action-${key}`} className={custom_class}>
            <td>{name}</td>
            <td>{email}</td>
            {role_html}
            {buttons_html}
          </tr>
        );
      });
    }
  };

  const renderContentUsers = () => {
    if (checkPermission(rbacRules.user_list.scopes)) {
      return (
        <div className="content-users">
          <div className="table-list-user">
            <table>
              <thead>
                <tr>
                  <th>{translate('name')}</th>
                  <th>{translate('email')}</th>
                  <th>{translate('role')}</th>
                  <th>{translate('actions')}</th>
                </tr>
              </thead>
              <tbody>{renderUsers()}</tbody>
            </table>
            <ModalConfirmDelete
              userId={deletingUserId}
              modalIsOpen={showDeleteModal}
              onClose={onCloseDeleteModal}
              showSnackBar={props.showSnackBar}
            />
            <ModalImportUsers
              modalIsOpen={showModalImport}
              onClose={onCloseImportModal}
              roles={roles}
              showSnackBar={props.showSnackBar}
            />
          </div>
        </div>
      );
    }
    return <Fragment />;
  };

  return (
    <div className="container">
      <div className="row">
        <header className="page-header">
          <h2 className="page-title">{translate('users/page_title')}</h2>
          <button type="button" className="btn-primary" onClick={() => setShowModalImport(true)}>
            {translate('create')}
          </button>
        </header>
        {renderContentUsers()}
      </div>
    </div>
  );
};

export default Users;
