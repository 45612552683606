import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Logout from '../pages/Logout';
import TenantConfig from '../pages/TenantConfig';
import TenantOu from '../pages/TenantOu';
import Activities from '../pages/Activities';
import Users from '../pages/Users/';
// import Roles from '../pages/Roles';
import UseTerms from '../pages/UseTerms';
import PrivatePolicy from '../pages/PrivacyPolicy';

const pages = [
  {
    exact: true,
    path: '/',
    auth: false,
    component: Home,
  },
  {
    path: '/dashboard',
    auth: true,
    component: Dashboard,
    scopes: ['tenant.processed_user.list', 'tenant.processed_user.get'],
  },
  {
    path: '/activities',
    auth: true,
    component: Activities,
    scopes: ['tenant.audit.list', 'tenant.audit.get'],
  },
  {
    path: '/users',
    auth: true,
    component: Users,
    scopes: ['tenant.user.list', 'tenant.user.get'],
  },
  // {
  //   path: '/roles',
  //   auth: true,
  //   component: Roles,
  // },
  {
    path: '/config',
    auth: true,
    component: TenantConfig,
    scopes: ['tenant.config.list', 'tenant.config.update'],
  },
  {
    path: '/ou',
    auth: true,
    component: TenantOu,
    scopes: ['tenant.ou.list', 'tenant.ou.update', 'tenant.ou.get'],
  },
  {
    path: '/use-terms',
    auth: false,
    component: UseTerms,
  },
  {
    path: '/private-policy',
    auth: false,
    component: PrivatePolicy,
  },
  {
    path: '/logout',
    auth: false,
    component: Logout,
  },
];

export default pages;
