import IAuthState from '../../interfaces/reducers/IAuthState';
import { authTokenIsValid } from '../../services/localStorage';

const authInitialState: IAuthState = {
  isAuth: authTokenIsValid(),
  accessToken: '',
  firebaseToken: '',
};

export default authInitialState;
