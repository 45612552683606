import React, { Component, Fragment } from 'react';
import { refreshAuthToken } from '../../utils/refreshToken';

import AppContext from '../AppContext';

interface IProps {
  reloadToken?: boolean;
}

class AuthMonitor extends Component<IProps> {
  static contextType = AppContext;
  state = {
    isRefreshingAuth: false,
  };

  _interval: NodeJS.Timeout | null = null;

  componentDidMount(): void {
    if (this._interval) {
      clearInterval(this._interval);
    }
    this._interval = setInterval(this.checkTokenExpires, 60000);
  }

  componentWillUnmount(): void {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  checkTokenExpires = (): void => {
    const token_expires = window.localStorage.getItem('token_expires');
    if (token_expires) {
      const dateNow = new Date();
      const tokenExpires = new Date(parseInt(token_expires));
      if (tokenExpires <= dateNow) {
        refreshAuthToken();
      }
    }
  };

  render(): JSX.Element {
    return <Fragment />;
  }
}

export default AuthMonitor;
