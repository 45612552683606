import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useTranslation } from 'react-i18next';
import IServerError from '../interfaces/serviceResponses/IServerError';
import IServerResponse from '../interfaces/serviceResponses/IServerResponse';
import i18n from './i18n';
import { getAccessToken } from './localStorage';

export default (baseUrl?: string, requestConfig?: AxiosRequestConfig): AxiosInstance => {
  const apiUrl = baseUrl ? baseUrl : window._env_.API_URL;
  const defaultConfig = {
    ...requestConfig,
  };

  if (apiUrl) {
    defaultConfig.baseURL = apiUrl;
  }

  const instance = axios.create(defaultConfig);
  instance.interceptors.request.use(authorizationInterceptor);

  return instance;
};

const authorizationInterceptor = async (config: AxiosRequestConfig) => {
  if (!config.headers.Authorization) {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
  }
  return config;
};

export const getTranslateErrorMessage = (error: IServerError): string => {
  const { error_code, message } = error;
  let i18nMessage = error_code ? i18n.t(error_code) : message;

  if (i18nMessage === error_code) {
    i18nMessage = message;
  }

  return i18nMessage;
};

export const getErrorMessage = (response: IServerResponse): string => {
  const { t: translate } = useTranslation();
  if (response && response.error) {
    return getTranslateErrorMessage(response.error);
  }

  if (response && response.errors.length) {
    return getTranslateErrorMessage(response.errors[0]);
  }

  return translate('connection_error');
};
