import IActivitiesFilters, { IActivitiesFiltersPayload } from '../interfaces/IActivitiesFilters';

export const convertActivitiesFiltersToPayload = (filters: IActivitiesFilters): IActivitiesFiltersPayload => {
  const payload: IActivitiesFiltersPayload = {
    page: filters.page,
  };

  if (filters.query) {
    payload.query = filters.query;
  }

  if (filters.ordered_field) {
    payload.ordered_field = filters.ordered_field;
  }

  if (filters.ordered_desc) {
    payload.ordered_desc = filters.ordered_desc;
  }

  return payload;
};
