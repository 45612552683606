import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locales from './locales';

const { archivfyPT, archivfyEN, archivfyES } = locales;

const options: InitOptions = {
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  detection: {
    order: ['navigator'],
  },
  resources: {
    pt: archivfyPT,
    en: archivfyEN,
    es: archivfyES,
  },
  fallbackLng: {
    default: ['pt'],
  },
  react: {
    bindI18n: 'languageChanged loaded',
    nsMode: 'default',
  },
};

export const availableLanguages = ['pt', 'en', 'es'];

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
