import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import statusList from '../../../constants/userStatus';
import IUsersFilters from '../../../interfaces/IUsersFilters';
import ISelectOption from '../../../interfaces/ISelectOption';
import { ICheckedOptions } from '../../../interfaces/ICheckboxOption';
import { getDomains } from '../../../services/users';
import InputDateRange from '../../../common/InputDateRange';
import FilterDropdown from './FilterDropdown';

interface IProps {
  searchUsers(params: IUsersFilters): void;
  paginateParams: IUsersFilters;
  setPaginateParams(params: IUsersFilters): void;
  setIsSubmiting(isSubmiting: boolean): void;
}

const SearchMenu = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { searchUsers, paginateParams, setPaginateParams, setIsSubmiting } = props;
  const [domainOptions, setDomainOptions] = useState<ISelectOption[]>([]);
  const [search, setSearch] = useState('');
  const timer = useRef<number>();

  useEffect(() => {
    const loadFilters = async () => {
      try {
        const response = await getDomains();
        if (response && response.data) {
          const { data: domains } = response;
          const options = domains.map(domain => ({ value: domain, label: domain }));
          setDomainOptions(options);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadFilters();
  }, []);

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = window.setTimeout(() => {
      const newParams = { ...paginateParams };

      if (search && search !== newParams.query) {
        newParams.query = search;
        setPaginateParams(newParams);
        setIsSubmiting(true);
      }
    }, 300);
    return () => {
      clearInterval(timer.current);
    };
  }, [search, paginateParams]);

  const _onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    searchUsers(paginateParams);
  };

  const onChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    clearInterval(timer.current);
    timer.current = window.setTimeout(() => {
      const newParams = { ...paginateParams };

      if (value) {
        newParams.query = value;
      } else {
        delete newParams.query;
      }
      setPaginateParams(newParams);
      setIsSubmiting(true);
    }, 300);
  };

  const onSelectDomain = (options: ICheckedOptions[]) => {
    const newParams = { ...paginateParams };

    if (options.length) {
      newParams.domain = options.map(domain => domain.value);
    } else {
      delete newParams.domain;
    }
    setPaginateParams(newParams);
    setIsSubmiting(true);
  };

  const onSelectStatus = (options: ICheckedOptions[]) => {
    const newParams = { ...paginateParams };

    if (options.length) {
      newParams.status = options.map(val => val.value);
    } else {
      delete newParams.status;
    }
    setPaginateParams(newParams);
    setIsSubmiting(true);
  };

  const onChangeDateRange = (start: Date | null, end: Date | null) => {
    const newParams = { ...paginateParams };
    if (start) {
      newParams.initial_date = start;
    }
    if (end) {
      newParams.final_date = end;
    } else {
      delete newParams.final_date;
    }

    setPaginateParams(newParams);
    setIsSubmiting(true);
  };

  return (
    <form onSubmit={_onSubmit} className="form-search-menu">
      <div className="content-form">
        <div className="search-box">
          <AiOutlineSearch className="search-icon" />
          <input
            type="text"
            className="search-input"
            name="query"
            value={search}
            placeholder={translate('dashboard/search')}
            onChange={onChangeQuery}
          />
        </div>
        <div className="select-box-filters">
          <FilterDropdown optionList={domainOptions} name={translate('filters/domain')} setFilters={onSelectDomain} />
          <FilterDropdown optionList={statusList} name={translate('filters/status')} setFilters={onSelectStatus} />
          {/* <select className="select-box" name="status" onChange={onSelectStatus}>
            <option value="">status</option>
            {statusList.map((option, key) => (
              <option value={option.value} key={key}>
                {option.label}
              </option>
            ))}
          </select> */}
          <InputDateRange
            className="select-box"
            inputClassName="input-date"
            valueStart={paginateParams.initial_date}
            valueEnd={paginateParams.final_date}
            onChange={onChangeDateRange}
          />
        </div>
      </div>
    </form>
  );
};

export default SearchMenu;
