import React, { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from 'react-icons/fa';
import initialActivitieListFilters from '../../constants/initialActivitieFilters';
import rbacRules from '../../constants/rbacRules';
import { IActivitieObject } from '../../interfaces/IActivitieResponse';
import IActivitiesFilters from '../../interfaces/IActivitiesFilters';
import { getAuditLog } from '../../services/logs';
import { checkPermission } from '../../services/rbac';
import { convertActivitiesFiltersToPayload } from '../../utils/activities';
import { getDateString, getTimeString } from '../../utils/date';

const Activities = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const [activities, setActivities] = useState<IActivitieObject[]>([]);
  const [paginateParams, setPaginateParams] = useState<IActivitiesFilters>(initialActivitieListFilters);
  const [isSubmiting, setIsSubmiting] = useState(true);
  const [search, setSearch] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const requestActivities = async () => {
      try {
        const query = convertActivitiesFiltersToPayload(paginateParams);
        const response = await getAuditLog(query);
        const { audits, total_pages } = response.data;
        const newPaginateParams = {
          ...paginateParams,
          pages: total_pages,
        };
        setIsSubmiting(false);
        setActivities(audits);
        setPaginateParams(newPaginateParams);
      } catch (error) {
        console.log(error);
        setIsSubmiting(false);
      }
    };

    if (isSubmiting) {
      requestActivities();
    }
  }, [paginateParams]);

  const onChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
    console.log(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setIsSubmiting(true);
        setPaginateParams({ ...paginateParams, query: value });
      }, 300)
    );
  };

  const setPage = (page: number) => {
    const newPaginateParams = {
      ...paginateParams,
      page,
    };

    setPaginateParams(newPaginateParams);
    setIsSubmiting(true);
  };

  const nextPage = () => {
    const { pages, page } = paginateParams;
    const newPage = Math.min(page + 1, pages);
    if (newPage !== page) {
      setPaginateParams({ ...paginateParams, page: newPage });
      setIsSubmiting(true);
    }
  };

  const prevPage = () => {
    const { page } = paginateParams;
    const newPage = Math.max(page - 1, 1);
    if (newPage !== page) {
      setPaginateParams({ ...paginateParams, page: newPage });
      setIsSubmiting(true);
    }
  };

  const renderPageNumbers = () => {
    const { pages, page } = paginateParams;
    const pagesButtons: JSX.Element[] = [];
    let pageLimit = Math.min(Math.ceil(page / 5) * 5, pages);
    let initialPage = Math.max(pageLimit - 4, 1);

    if (pages > initialPage + 4) pageLimit += 1;
    if (initialPage > 5) initialPage -= 1;

    for (let i = initialPage; i <= pageLimit; i++) {
      pagesButtons.push(
        <button
          key={`page-${i}`}
          type="button"
          className={`btn-paginate ${i === page ? 'btn-active' : ''}`}
          onClick={() => setPage(i)}>
          {(i === pageLimit && i > 1) || (i === initialPage && initialPage > 1) ? '...' : i}
        </button>
      );
    }

    return pagesButtons;
  };

  const renderList = () => {
    if (activities.length) {
      return activities.map((activitieInfo, key) => {
        const { action, object_name, done_by, created_date } = activitieInfo;
        let createdDateString = '-';
        let createdTimeString = '-';
        if (created_date) {
          const createdDate = new Date(created_date);
          createdDateString = getDateString(createdDate);
          createdTimeString = getTimeString(createdDate);
        }
        const actionString = action === 'DELETE' ? translate('activities/deletion') : '';

        return (
          <tr key={`action-${key}`}>
            <td>{object_name}</td>
            <td>{actionString}</td>
            <td>{done_by}</td>
            <td>
              {createdDateString}
              <br />
              {createdTimeString}
            </td>
          </tr>
        );
      });
    }
  };

  const setOrder = (field: string) => {
    const { ordered_desc, ordered_field } = paginateParams;
    let newOrderedDesc = true;
    const newOrderedField = field;
    if (ordered_field === field && ordered_desc) {
      newOrderedDesc = !ordered_desc;
    }
    setPaginateParams({ ...paginateParams, ordered_desc: newOrderedDesc, ordered_field: newOrderedField });
    setIsSubmiting(true);
  };

  const renderAuditContent = () => {
    if (checkPermission(rbacRules.audit_list.scopes)) {
      return (
        <div className="content-dashboard list-user-content">
          <form onSubmit={event => event.preventDefault} className="form-search-menu">
            <div className="content-form">
              <div className="search-box">
                <AiOutlineSearch className="search-icon" />
                <input
                  type="text"
                  className="search-input"
                  name="query"
                  value={search}
                  placeholder="Buscar Colaborador"
                  onChange={onChangeQuery}
                />
              </div>
            </div>
          </form>
          <div className="table-list-user table-dashboard">
            <table>
              <thead>
                <tr>
                  <th>
                    <span className="order-button" onClick={() => setOrder('object_name')}>
                      {translate('user')}
                      {paginateParams.ordered_field === 'object_name' ? headerIcon : ''}
                    </span>
                  </th>
                  <th>
                    <span className="order-button" onClick={() => setOrder('action')}>
                      {translate('action')}
                      {paginateParams.ordered_field === 'action' ? headerIcon : ''}
                    </span>
                  </th>
                  <th>
                    <span className="order-button" onClick={() => setOrder('done_by')}>
                      {translate('activities/who_action')}
                      {paginateParams.ordered_field === 'done_by' ? headerIcon : ''}
                    </span>
                  </th>
                  <th>
                    <span className="order-button" onClick={() => setOrder('created_date')}>
                      {translate('activities/action_date')}
                      {paginateParams.ordered_field === 'created_date' ? headerIcon : ''}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
          </div>
          <div className="paginate-nav">
            <button type="button" className="btn-paginate" onClick={prevPage}>
              <FaChevronLeft />
            </button>
            {renderPageNumbers()}
            <button type="button" className="btn-paginate" onClick={nextPage}>
              <FaChevronRight />
            </button>
          </div>
        </div>
      );
    }
  };

  const headerIcon = paginateParams.ordered_desc ? (
    <FaChevronDown className="icon" size={10} />
  ) : (
    <FaChevronUp className="icon" size={10} />
  );

  return (
    <div className="container">
      <div className="row">
        <h2 className="page-title">{translate('activities/page_title')}</h2>
        {renderAuditContent()}
      </div>
    </div>
  );
};

export default Activities;
