import statusList, { userStatus, COMPLETE } from '../constants/userStatus';
import i18n from '../services/i18n';

export const getStatusName = (statusNumber: number, process_status = false): string => {
  if (statusNumber === null) {
    return i18n.t('not_started');
  }

  if (!process_status && statusNumber === userStatus[COMPLETE].value) {
    return userStatus[COMPLETE].label;
  }

  const status = statusList.find(item => item.value.toString() === statusNumber.toString());

  if (status) {
    return status.label;
  }

  return i18n.t('invalid_status');
};
