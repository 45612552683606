import ITenantConfigResponse from '../../interfaces/serviceResponses/ITenantConfigResponse';
import ITenantOuResponse from '../../interfaces/serviceResponses/ITenantOuResponse';
import ITenantConfig from '../../interfaces/ITenantConfig';
import http, { getErrorMessage } from '../http';
import ITenantOu from '../../interfaces/ITenantOu';

const { get, post, patch } = http();

export const getTenantConfig = (): Promise<ITenantConfigResponse> => {
  return new Promise<ITenantConfigResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/customer`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getListTenantOu = (): Promise<ITenantOuResponse> => {
  return new Promise<ITenantOuResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/ou`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTenantOu = (id: string): Promise<ITenantOuResponse> => {
  return new Promise<ITenantOuResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/ou/${id}`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateTenant = (tenantConfig: ITenantConfig): Promise<ITenantConfigResponse> => {
  return new Promise<ITenantConfigResponse>(async (resolve, reject) => {
    try {
      const response = await patch(`/api/customer`, tenantConfig);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateTenantOu = (tenantOu: ITenantOu, id: string): Promise<ITenantOuResponse> => {
  return new Promise<ITenantOuResponse>(async (resolve, reject) => {
    try {
      const { team_drive_id, folder_hierarchy, permissioned_emails } = tenantOu;
      const newTenantOu = { team_drive_id, folder_hierarchy, permissioned_emails };

      const response = await patch(`/api/ou/${id}`, newTenantOu);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createTenantOu = (tenantOu: ITenantOu): Promise<ITenantOuResponse> => {
  return new Promise<ITenantOuResponse>(async (resolve, reject) => {
    try {
      const response = await post(`/api/ou`, tenantOu);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteTenantOu = (id: string): Promise<ITenantOuResponse> => {
  return new Promise<ITenantOuResponse>(async (resolve, reject) => {
    try {
      const response = await http().delete(`/api/ou/${id}`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export default { getTenantConfig, updateTenant };
