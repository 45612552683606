import { getUserScopes } from './localStorage';

export const checkPermission = (actions: string[]): boolean => {
  const scopes = getUserScopes();

  for (const action of actions) {
    if (scopes && scopes.includes(action)) {
      return true;
    }
  }

  return false;
};
