import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { IoLogOutOutline } from 'react-icons/io5';
import logo from '../../assets/images/logo-beta.svg';
import { getLocalUser, userLogout } from '../../services/localStorage';
import { BiMessageDetail } from 'react-icons/bi';

interface IProps {
  toggleSidebar(): void;
}

const TopBar = ({ toggleSidebar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const {
    email,
    profile: { name, image },
  } = getLocalUser();
  const [showDropdown, setShowDropdown] = useState(false);

  const logout = () => {
    userLogout();
    history.push('/logout');
  };

  const renderDropdown = () => {
    if (showDropdown) {
      return (
        <div className="content-dropdown">
          <ul>
            <li>
              <a href="https://suporte.ipnet.cloud/" target="_blank" rel="noopener noreferrer">
                <BiMessageDetail className="item-icon" />
                {translate('support')}
              </a>
              <button type="button" onClick={logout}>
                <IoLogOutOutline className="item-icon" />
                {translate('logout')}
              </button>
            </li>
          </ul>
        </div>
      );
    }

    return <Fragment />;
  };

  return (
    <header className="panel-top-bar">
      <div className="logo-box">
        <NavLink to="#" onClick={toggleSidebar} className="hamburg-icon">
          Abre ou fecha o menu
        </NavLink>
        <img src={logo} alt="Logo Ipnet" />
      </div>

      <div className="user-info">
        <div className="profile">
          <div className="name">{name}</div>
          <div className="email">{email}</div>
        </div>
        <div className="image">
          <button type="button" className="toggle-menu" onClick={() => setShowDropdown(!showDropdown)}>
            <img src={image} alt={name} />
          </button>
        </div>
        {renderDropdown()}
      </div>
    </header>
  );
};

export default TopBar;
