import React from 'react';
import { useHistory } from 'react-router';
import logo from '../../assets/images/customer_logo.png';

// prettier-ignore
const PrivatePolicy = (): JSX.Element => {
  const history = useHistory();
  return (
    <div className="container page-privacy">
      <div className="row">
        <header className="institutional page-header">
          <img src={logo} alt="Logo Ipnet" />
          <h2 className="page-title">Termos de Uso</h2>
        </header>
        <div className="page-content">
          <div className="page-text-box content-privacy">
          <p>
              Seja bem-vindo (a)!
              <br />
              Ficamos muito felizes por escolher a Plataforma <strong>Archivfy</strong>!
              <br />
              Caso esteja com muita pressa em acessar a Plataforma, disponibilizamos um breve resumo no quadro abaixo.
            </p>

            <div className="border-text">
              <h2 className="text-upper">Direitos dos Titulares de Dados pessoais</h2>
              <p>
                <strong>Vocês</strong> são os titulares (pessoas naturais) de dados pessoais a quem se referem os dados pessoais que serão coletados por <strong>Nós</strong>.
                <br />
                A legislação brasileira concede certos direitos à Pessoa Física quanto a seus dados pessoais. Dessa forma, <strong>Nós</strong> garantimos transparência e controle de acesso aos titulares de dados pessoais, proporcionando-lhes a melhor experiência possível durante a utilização da Plataforma. Os direitos do titular dos dados pessoais são os seguintes:
              </p>
              <ul>
                <li>a) Direito de confirmação da existência de tratamento - Direito de solicitar a confirmação da existência de tratamento aos seus dados pessoais através de informações claras sobre a origem dos dados, a inexistência de registro, os critérios utilizados e a finalidade do tratamento;</li>
                <li>b) Direito de acesso - direito de ser informado e solicitar acesso aos dados pessoais tratados por <strong>Nós</strong>;</li>
                <li>c) Direito de retificação - direito de solicitar que <strong>Nós</strong> alteremos ou atualizemos os seus dados pessoais quando os mesmos estiverem incorretos ou incompletos;</li>
                <li>d) Direito de exclusão - direito de solicitar a eliminação de seus dados pessoais coletados por <strong>Nós</strong>;</li>
                <li>e) Direito de restrição - direito de solicitar que <strong>Nós</strong> deixemos, temporária ou permanentemente, de processar todos ou alguns dos seus dados pessoais;</li>
                <li>f) Direito de oposição – direito de se opor, a qualquer momento, (i) ao processamento de seus dados pessoais por motivos relacionados à sua situação particular e (ii) à manipulação dos seus dados pessoais para fins de marketing direto;</li>
                <li>g) Direito à portabilidade de dados - direito de solicitar uma cópia dos seus dados pessoais em formato eletrônico e/ou transmitir os referidos dados pessoais para utilização no serviço de terceiros;</li>
                <li>h) Direito a não se submeter a decisões automatizadas - direito de não se submeter a decisões tomadas de forma unicamente automatizada, incluindo no que diz respeito à definição de perfis, caso a decisão exerça efeito jurídico sobre <strong>Você</strong> ou produza um efeito igualmente significativo.</li>
              </ul>
            </div>
            <br />
            <div className="border-text">
              <h2 className="text-upper">Informações / Dados pessoais Coletados e sua Finalidade</h2>
              <p>Na tabela abaixo exibimos quais dados serão coletados, sua categoria e finalidade.</p>
              <table>
                <tr>
                  <td className="text-upper">Dado coletado</td>
                  <td className="text-upper">Classificação</td>
                </tr>
                <tr>
                  <td>
                    Razão social;<br />
                    CNPJ;<br />
                    Endereço;<br />
                    Inscrição estadual;<br />
                    Nome completo do responsável;
                  </td>
                  <td>Dados cadastrais</td>
                </tr>
                <tr>
                  <td>
                    Identificação dos projetos (Nomes e IDs);<br />
                    Identificação das contas de faturamento;<br />
                    Funções e permissões do usuário;<br />
                    Domínios do Google Cloud Platform de <strong>Vocês</strong>; <br />
                    E-mails dos usuário no Google Cloud Platform;<br />
                    Foto do usuário admin;
                  </td>
                  <td>Dados de utilização</td>
                </tr>
              </table>
              <p className="mgt-10">Os dados coletados serão utilizados com as seguintes finalidades:</p>
              <table className="table-tree-columns">
                <tr>
                  <td>Dado coletado</td>
                  <td>Finalidade</td>
                  <td>Base legal</td>
                </tr>
                <tr>
                  <td>Dados cadastrais</td>
                  <td>Te identificar;<br />Enviar comunicações a <strong>Você</strong>;</td>
                  <td rowSpan={2}>
                    Art. 7º O tratamento de dados pessoais somente poderá ser realizado nas seguintes hipóteses:
                    <br />
                    I - mediante o fornecimento de consentimento pelo titular;
                  </td>
                </tr>
                <tr>
                  <td>Dados de utilização</td>
                  <td>
                    Prestar os serviços propostos por <strong>Nós</strong>;<br />
                    Identificar os projetos e contas de faturamento no Google Cloud Platform;
                  </td>
                </tr>
              </table>
            </div>
            <p>Para que todas as informações sejam corretamente compreendidas, nós apresentamos nossa Política de Privacidade de forma transparente e acessível, como poderá ser visto a seguir:</p>
            
            <h2 className="text-upper">
              Destacamos que é extremamente importante a compreensão completa e a leitura total deste documento.
            </h2>

            <h2 className="text-upper">Termos gerais</h2>
            <p>
              <strong>1.1. Geral.</strong> Esta <strong>Política de Privacidade</strong> foi concebida para comunicar as nossas práticas relativas ao tratamento (inclui coleta, armazenamento, utilização, processamento e compartilhamento) de seus dados pessoais por <strong>Nós</strong> (doravante assim denominada ou somente “Plataforma”), que engloba o website e seus subdomínios, aplicativos, programas e demais extensões. A Plataforma é de propriedade da <strong>IPNET SERVIÇOS EM NUVEM E DESENVOLVIMENTO DE SISTEMAS LTDA.</strong>, sociedade inscrita no CNPJ sob o nº 32.578.382/0001-21, com sede na Rua Siqueira Campos, n° 43, cobertura 1 - Parte, Copacabana, Rio de Janeiro/RJ, CEP: 22031-071, e está sob sua responsabilidade e gestão.
            </p>
            <p>
              <strong>1.2. O que são Dados Pessoais.</strong> Os dados pessoais são aqueles informados por <strong>Vocês</strong>, os quais podem ser usados para identificá-los individualmente.
            </p>
            <p>
              <strong>1.3. Controlador dos Dados Pessoais.</strong> Para todos os fins, a <strong>IPNET SERVIÇOS EM NUVEM E DESENVOLVIMENTO DE SISTEMAS LTDA.</strong> será considerada a controladora dos dados pessoais dos titulares. 
            </p>
            <p>
              <strong>1.4. Autorização de Coleta de Informações.</strong> Ao utilizar a plataforma, <strong>Você</strong> deverá aceitar e autorizar a coleta de informações apenas para o cumprimento dos serviços ora contratados, através do botão “li e concordo com os termos”, nos termos da presente política de privacidade. Ao aceitar essa política de privacidade, <strong>Você</strong> declara consentir com os Termos de processamento e segurança de dados da Google Drive Platform <strong><a href="https://cloud.google.com/terms/data-processing-terms" target="_blank" rel="noopener noreferrer">(https://cloud.google.com/terms/data-processing-terms)</a>.</strong>
              <br />
              <span className="sub-paragraph">
                <strong>1.4.1. Você</strong> poderá desistir de consentir com as disposições desta Política de Privacidade, a qualquer momento, bastando para tal que notifique a <strong>Archivfy</strong> através do e-mail <strong><u>suporte_apps@ipnet.cloud.</u></strong>
              </span>
            </p>
            <p>
              <strong>1.5. Regulamentação.</strong> A Plataforma atua de acordo com a legislação brasileira, atendendo, inclusive e especialmente, com os termos da Lei nº. 12.965/2014 (o Marco Civil da Internet) e da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados) e demais leis que venham a ser aplicadas.
            </p>
            <p>
              <strong>1.6. Contato.</strong> Em caso de dúvida ou sugestões sobre a Política de Privacidade da Plataforma ou qualquer outra informação, <strong>Você</strong> poderá entrar em contato com os administradores através do e-mail <strong><u>suporte_apps@ipnet.cloud</u></strong>, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h 30 min às 18h.
            </p>
            <p>
              <strong>1.7. Controle de Informações.</strong> Esta Política descreve quais os dados pessoais podem ser coletados de <strong>Vocês</strong>, enquanto utilizam os serviços da Plataforma, como essas informações podem ser utilizadas e as precauções tomadas contra o acesso ou uso destas informações de forma não autorizada.
            </p>

            <h2 className="text-upper">Dados pessoais coletados</h2>
            <p>
              <strong>2.1. Nós</strong> poderemos coletar, armazenar e utilizar as seguintes informações e/ou dados pessoais de <strong>Vocês</strong>:
            </p>
            <ul>
              <li>a) Razão social;</li>
              <li>b) CNPJ;</li>
              <li>c) Endereço;</li>
              <li>d) Inscrição estadual;</li>
              <li>e) Nome completo do responsável;</li>
              <li>f) E-mail do usuário no Google Cloud Platform;</li>
              <li>g) Identificação dos projetos (Nome e ID);</li>
              <li>h) Identificação das contas de faturamento;</li>
              <li>i) Domínio no Google Cloud Platform de <strong>Vocês</strong>;</li>
              <li>j) Funções e permissões do usuário;</li>
              <li>k) Foto do usuário admin.</li>
            </ul>
            <br />
            <p>
              <strong>2.2. Nós</strong> também poderemos coletar, armazenar e utilizar as seguintes informações: 
            </p>
            <ul>
              <li>a) Registro em banco de dados de quaisquer arquivos ou informação carregados na <strong>Nossa</strong> Plataforma pelos titulares;</li>
              <li>b) Os detalhes de acesso à <strong>Archivfy</strong> e dos recursos que o titular acessou;</li>
              <li>c) Informações do dispositivo de acesso utilizado, incluindo, por exemplo, modelo do hardware, sistema operacional e versão, 	nomes e versões dos arquivos, idioma de preferência, identificador exclusivo do dispositivo, identificadores de publicidade, número de série, informações referentes ao movimento do dispositivo e informações de rede;</li>
              <li>d) Informações de registro do servidor que podem incluir informações como endereço IP do dispositivo.</li>
              <li>e) Mapeamento de cliques no navegador, dados de navegação, estatísticos, demográficos, entre outros.</li>
              <li>f) Mapeamento de cliques nos aplicativos e dados de navegação;</li>
            </ul>
            <br />

            <h2 className="text-upper">Compartilhamento dos dados pessoais coletados</h2>
            <p>
              <strong>3.1. Exceções para Compartilhamento de Dados Pessoais.</strong> <strong>Nós</strong> não divulgamos a terceiros qualquer dado pessoal fornecido por <strong>Vocês</strong>, através da <strong>Nossa</strong> plataforma, exceto:
            </p>
            <ul>
              <li>a) Casos em que <strong>Nós</strong> formos obrigados a divulgar ou partilhar os dados coletados, a fim de cumprir ordem judicial, ou para efeitos de prevenção de fraude ou outros crimes, bem como em resposta à solicitação de informações apresentada por autoridade competente, se entendermos que a divulgação está de acordo ou é exigida conforme as leis, regulamentos ou procedimentos processuais aplicáveis;</li>
              <li>b) Para proteger os direitos, bens ou a segurança de <strong>Nossa</strong> Plataforma;</li>
              <li>c) Com agentes da lei e/ou autoridades governamentais, se entender-se que suas ações são inconsistentes em relação às disposições em nossos termos de uso, ou para proteger os direitos, a propriedade ou a <strong>Nossa</strong> segurança, a de <strong>Vocês</strong> ou de outros;</li>
              <li>d) Mediante ação própria de <strong>Vocês</strong>; </li>
              <li>e) Nos casos de venda, parcial ou total, do negócio ou de seus ativos, ou como parte de qualquer reorganização ou reestruturação do negócio, fusão, cisão ou incorporação, de modo que <strong>Nós</strong> poderemos compartilhar as informações de <strong>Vocês</strong> com terceiros que façam parte dos respectivos negócios, tomando as medidas necessárias para garantir que os direitos de privacidade continuem a ser protegidos, conforme esta Política.</li>
            </ul>
            <br />
            <p>
              <strong>3.2. Autorizações de Contato e Divulgação.</strong> <strong>Vocês</strong> autorizam a <strong>Archivfy</strong> a contatá-los utilizando qualquer um dos meios de contato informados no cadastro na Plataforma.
            </p>

            <h2 className="text-upper">Cookies</h2>
            <p>
              <strong>4.1. O que são os Cookies.</strong> Um cookie é um trecho de informação armazenado localmente no seu computador ou dispositivo, e que contém informação acerca das atividades deste na Internet. 
            </p>
            <p>
              <strong>4.2. Cookies Utilizados.</strong> Os cookies podem ser usados de diferentes formas e modalidades. <strong>Nós</strong> poderemos utilizar:
            </p>
            <ul>
              <li>a) <u>Cookies de Desempenho:</u> Este tipo de cookie coleta informações anônimas sobre a forma como <strong>Vocês</strong> utilizam a plataforma, de forma a otimizá-la. As informações coletadas por estes cookies nunca contêm detalhes pessoais a partir dos quais seja possível identificá-los.</li>
              <li>b) <u>Cookies Funcionais:</u> Estes cookies são usados por <strong>Nós</strong> por algumas de suas funcionalidades, buscando oferecer uma melhor experiência ao navegar pela plataforma. </li>
            </ul>
            <p>
              <strong>4.3. Acesso aos Cookies.</strong> O acesso aos cookies termina assim que <strong>Você</strong> fecha o navegador. É dada a Você a possibilidade de aceitar ou recusar os cookies. 
            </p>
            <p>
              <strong>4.4. Consentimento para Utilização de Cookies.</strong> É necessária sua aceitação, antes de iniciada a seção, para que os Cookies sejam utilizados. 
            </p>
            <p>
              <strong>4.5. Opção sem Cookies.</strong> O titular pode optar por recusar o uso de cookies. Se <strong>Você</strong> optar por não os aceitar, o seu acesso à maior parte das informações disponíveis na Plataforma não será comprometido. Contudo, o titular poderá não conseguir usar plenamente os serviços oferecidos.
            </p>
                

            <h2 className="text-upper">Acesso e correção de dados pessoais</h2>
            <p>
              <strong>5.1. Acesso e Retificação de Dados Pessoais.</strong> <strong>Vocês</strong> têm direito a acessar os seus dados pessoais sob <strong>Nossa</strong> posse, contatando os administradores através do e-mail <strong><u>suporte_apps@ipnet.cloud</u></strong>, que será respondido em horário comercial, de segunda-feira a sexta-feira, das 9h 30 min às 18h, no prazo de 15 (quinze) dias, cumulada com descrição da forma, finalidade e duração do tratamento dos dados pessoais, que poderá ser enviada por e-mail ou através de carta, de acordo com o previsto no artigo 9º da LGPD.
            </p>
            <p>
              <strong>5.2. Exclusão. Você</strong> tem direito de solicitar a exclusão dos seus dados pessoais armazenados na <strong>Archivfy</strong>, a qualquer momento, salvo nos casos em que houver obrigação legal ou decisão judicial para a manutenção do armazenamento dos dados, de acordo com o disposto nos artigos 18, XVI, e 16 da LGPD.
            </p>
            <p>
              <strong>5.3. Incorreção de Informações.</strong> É responsabilidade do titular manter suas informações atualizadas, sendo certo que, no caso de incorreção, <strong>Nós</strong> poderemos atualizá-las ou excluí-las, excetuando-se os casos de necessidade de manutenção para fins comerciais legítimos ou jurídicos. 
            </p>
            <p>
              <strong>5.4. Medidas Necessárias de Proteção.</strong> <strong>Nós</strong> tomamos as medidas de segurança necessárias para proteger seus dados pessoais contra a perda, utilização indevida, acesso, divulgação, alteração ou destruição não autorizados.
            </p>
            <p>
              <strong>5.5. Proteção de Senhas.</strong> <strong>Vocês</strong> são igualmente responsáveis por tomar medidas cabíveis destinadas a proteger as suas senhas, nomes de usuário e outros recursos de acesso especiais ao cadastro pessoal na <strong>Archivfy.</strong>
            </p>

            <h2 className="text-upper">Notificação de modificações na política de privacidade</h2>
            <p>
              <strong>6.1. Alterações na Política de Privacidade. Nós</strong> podemos alterar a Política de Privacidade da Plataforma de tempos em tempos. O uso de informações coletadas agora está sujeito à Política de Privacidade em vigor. Se <strong>Nós</strong> realizarmos mudanças na forma como utiliza as informações pessoais, notificaremos <strong>Vocês</strong> através do envio de e-mail. 
            </p>
            <p>
              <strong>6.2. Ajustes Não Notificados.</strong> Pequenos ajustes nesta Política de Privacidade podem ocorrer sem afetar significativamente as formas em que <strong>Nós</strong> tratamos as informações coletadas, de forma que estes não precisarão ser informados.
            </p>

            <h2 className="text-upper">Comunicação</h2>
            <p>
              <strong>7.1. Envio de Comunicações. Vocês</strong>, ao se cadastrarem, concordam que <strong>Nós</strong> enviemos para o seu e-mail notificações, publicidades, novidades sobre os serviços e informações importantes sobre a utilização da Plataforma que necessitem da sua atenção.
              <br />
              <span className="sub-paragraph">
                <strong>7.1.1. Opção por Não Recebimento.</strong> Ao receber um e-mail em nome da Plataforma, haverá a possibilidade de optar por não mais recebê-los, utilizando da opção opt-out ou através de solicitação por e-mail.
              </span>
            </p>
            <p>
              <strong>7.2. Política AntiSpam.</strong> A Plataforma toma os cuidados necessários para evitar o envio não solicitado de e-mails. 
            </p>
            <p>
              <strong>7.3. Confidencialidade.</strong> É assegurado o máximo de confidencialidade no tratamento da lista de telefones e e-mails durante as tarefas regulares de <strong>Nossa</strong> administração.
            </p>

            <h2 className="text-upper">Segurança de dados pessoais</h2>
            <p>
              <strong>8.1. Armazenamento.</strong> Os dados pessoais de sua conta são armazenados de forma segura e todas as informações da plataforma trafegam de forma segura em servidores do Google localizados nos Estados Unidos da América, sendo que apenas os colaboradores autorizados por <strong>Nós</strong> podem ter acesso às suas informações pessoais, estando obrigatoriamente sujeitos aos deveres de confidencialidade e rigoroso respeito à sua privacidade nos termos desta Política.
            </p>
            <p>
              <strong>8.2. Segurança da Informação.</strong> Todas as transações da <strong>Archivfy</strong> são criptografadas, sujeitas a backup de dados, ferramentas de monitoramento, políticas de segurança, controles de acesso para colaboradores, com softwares de segurança atualizados.
            </p>
            <p>
              <strong>8.3. Notificação.</strong> Se <strong>Nós</strong> tomarmos conhecimento de qualquer violação à segurança própria ou a empresas de hospedagem, incluindo invasões, vazamentos de dados ou qualquer outro incidente de segurança da informação, iremos notificar as autoridades nacionais, <strong>Vocês</strong>, se afetados, acerca dessa violação e iremos fornecer o máximo de detalhes referente à natureza, extensão da violação e os dados comprometidos, em prazo razoável, de acordo com o disposto nos artigos 48, § 1º, da LGPD.
            </p>
            <p>
              <strong>8.4.  Prazo de Armazenamento. Nós</strong> armazenaremos seus dados durante todo o período em que seu cadastro estiver ativo.
            </p>

            <h2 className="text-upper">Disposições gerais</h2>
            <p>
              <strong>9.1.  Responsabilidade do Usuário. Vocês</strong> serão responsáveis por Nos indenizar por todos os custos e danos que este possa incorrer, como resultado da violação desta Política de Privacidade por si provocados.
            </p>
            <p>
              <strong>9.2. Cooperação com Autoridades. Nós</strong> cooperamos plenamente com quaisquer autoridades ou tribunais que venham a solicitar a divulgação da identidade ou localização de qualquer pessoa que tenha inserido qualquer material na Plataforma que violem as Cláusulas dispostas nesta Política. 
            </p>
            <p>
              <strong>9.3. Limitação de Responsabilidade.</strong> Esta Política de Privacidade aborda apenas o uso e divulgação de informações coletadas pela Nossa Plataforma. Se <strong>Vocês</strong> divulgarem suas informações a websites de terceiros, regras diferentes podem ser aplicadas à utilização das suas informações. 
            </p>
            <p>
              Criada em 14 de setembro de 2020.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivatePolicy;
