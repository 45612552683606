import { AxiosRequestConfig } from 'axios';
import IDeleteUser from '../../interfaces/IDeleteUser';
import IUserForm from '../../interfaces/IUserForm';
import IExportUser from '../../interfaces/IExportUser';
import { IUsersFiltersPayload } from '../../interfaces/IUsersFilters';
import IDomainsResponse from '../../interfaces/serviceResponses/IDomainsResponse';
import IGSuiteUserResponse from '../../interfaces/serviceResponses/IGSuiteUserResponse';
import IUserResponse, {
  ISystemCreateUserResponse,
  ISystemUserResponse,
} from '../../interfaces/serviceResponses/IUserResponse';
import http, { getErrorMessage } from '../http';

const { get, post, patch, delete: deleteRequest } = http();

export const getUsersList = (query: IUsersFiltersPayload): Promise<IUserResponse> => {
  return new Promise<IUserResponse>(async (resolve, reject) => {
    const options = {
      params: {
        ...query,
      },
    };

    try {
      const response = await get(`/api/user`, options);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSystemUsersList = (): Promise<ISystemUserResponse> => {
  return new Promise<ISystemUserResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/system/user`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getDomains = (): Promise<IDomainsResponse> => {
  return new Promise<IDomainsResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/customer/domain`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getGSuiteUsers = (): Promise<IGSuiteUserResponse> => {
  return new Promise<IGSuiteUserResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/system/user/gsuite`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createUserFromGSuite = (newUser: IUserForm): Promise<ISystemCreateUserResponse> => {
  return new Promise<ISystemCreateUserResponse>(async (resolve, reject) => {
    try {
      const response = await post(`/api/system/user`, newUser);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteUsers = (usersList: IDeleteUser[]): Promise<IUserResponse> => {
  return new Promise<IUserResponse>(async (resolve, reject) => {
    try {
      const options: AxiosRequestConfig = {
        data: {
          users: usersList,
        },
      };

      const response = await deleteRequest(`/api/user`, options);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const patchSystemUser = (userId: string, role_id: string): Promise<ISystemUserResponse> => {
  return new Promise<ISystemUserResponse>(async (resolve, reject) => {
    try {
      const response = await patch(`/api/system/user/${userId}`, { role_id });

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteSystemUser = (userId: string): Promise<ISystemUserResponse> => {
  return new Promise<ISystemUserResponse>(async (resolve, reject) => {
    try {
      const response = await deleteRequest(`/api/system/user/${userId}`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const exportUsers = (usersList: IExportUser[], type: string, name: string): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    const options: AxiosRequestConfig = {
      data: {
        file_type: type,
        file_name: name,
        users: usersList,
      },
    };

    try {
      const response = await post(`/api/user/export`, options);
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export default { getUsersList };
