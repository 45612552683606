import IRBACRules from '../interfaces/IRBACRules';

const rbacRules: IRBACRules = {
  user_list: {
    scopes: ['tenant.user.list', 'tenant.user.get'],
  },
  user_create: {
    scopes: ['tenant.user.create'],
  },
  user_edit: {
    scopes: ['tenant.user.update'],
  },
  user_delete: {
    scopes: ['tenant.user.delete'],
  },
  processed_user_list: {
    scopes: ['tenant.processed_user.list', 'tenant.processed_user.get'],
  },
  processed_user_create: {
    scopes: ['tenant.processed_user.create'],
  },
  processed_user_edit: {
    scopes: ['tenant.processed_user.update'],
  },
  processed_user_delete: {
    scopes: ['tenant.processed_user.delete'],
  },
  role_list: {
    scopes: ['tenant.role.list', 'tenant.role.get'],
  },
  role_create: {
    scopes: ['tenant.role.create'],
  },
  role_edit: {
    scopes: ['tenant.role.update'],
  },
  role_delete: {
    scopes: ['tenant.role.delete'],
  },
  tenant_config: {
    scopes: ['tenant.config.list', 'tenant.config.update'],
  },
  tenant_ou: {
    scopes: ['tenant.ou.list', 'tenant.ou.update', 'tenant.ou.get'],
  },
  audit_list: {
    scopes: ['tenant.audit.list', 'tenant.audit.get'],
  },
};

export default rbacRules;
