import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa';
import SystemModal from '../../common/SystemModal';
import { getGSuiteUsers } from '../../services/users';
import { IGSuiteUser } from '../../interfaces/serviceResponses/IGSuiteUserResponse';
import ModalFormUser from './ModalFormUser';
import { IRoleObject } from '../../interfaces/serviceResponses/IRolesResponse';
import { AiOutlineSearch } from 'react-icons/ai';

interface IProps {
  modalIsOpen: boolean;
  onClose(created: boolean): void;
  roles: IRoleObject[];
  showSnackBar(message: string, type: string): void;
}

const ModalImportUsers = ({ modalIsOpen, onClose, roles, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [allUsersList, setAllUsersList] = useState<IGSuiteUser[]>([]);
  const [tempUsersList, setTempUsersList] = useState<IGSuiteUser[]>([]);
  const [visibleUserList, setVisibleUsersList] = useState<IGSuiteUser[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userToAdd, setUserToAdd] = useState<IGSuiteUser>();
  const [createdUser, setCreatedUser] = useState(false);
  const [numUsers, setNumUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [numMaxPages, setNumMaxPages] = useState(0);
  const [numVisibleUsers, setNumVisibleUsers] = useState(10);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await getGSuiteUsers();
        setAllUsersList(response.data);
        setTempUsersList(response.data);
        setNumUsers(response.data.length);
        setNumMaxPages(Math.floor(response.data.length / numVisibleUsers));
        const initialArrayNumber = numVisibleUsers * currentPage;
        setVisibleUsersList(response.data.slice(initialArrayNumber, initialArrayNumber + numVisibleUsers));
      } catch (error) {
        console.error(error);
      }
    };
    loadUsers();
  }, []);

  useEffect(() => {
    let newUserList = allUsersList;
    setTempUsersList(newUserList);
    if (searchString !== '') {
      newUserList = newUserList.filter(user => user.name.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
      setTempUsersList(newUserList);
    }
    const newNumUsers = newUserList.length;
    const newNumMaxPages = Math.floor(newNumUsers / numVisibleUsers);
    const initialArrayNumber = numVisibleUsers * currentPage;
    setNumUsers(newNumUsers);
    setNumMaxPages(newNumMaxPages);
    setVisibleUsersList(newUserList.slice(initialArrayNumber, initialArrayNumber + numVisibleUsers));

    setRefresh(false);
  }, [currentPage, refresh, numVisibleUsers, allUsersList]);

  const openAddUserModal = (user: IGSuiteUser) => {
    setUserToAdd(user);
    setShowAddUserModal(true);
  };

  const onCloseFormModal = (created: boolean) => {
    setShowAddUserModal(false);
    if (created) {
      setCreatedUser(created);
      const newAllUsersList = allUsersList;
      if (userToAdd) {
        newAllUsersList.splice(allUsersList.indexOf(userToAdd), 1);
        if (userToAdd) setAllUsersList(newAllUsersList);
        setRefresh(true);
      }
    }
  };

  const _onClose = () => {
    onClose(createdUser);
  };

  const onChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchString(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setCurrentPage(0);
        setRefresh(true);
      }, 200)
    );
  };

  const renderPageNumbers = () => {
    const pagesButtons: JSX.Element[] = [];

    let initialPage = 0;
    let pageLimit = numMaxPages;

    initialPage = currentPage - 2 > 0 ? currentPage - 2 : 0;
    pageLimit = initialPage + 4 < numMaxPages ? initialPage + 4 : numMaxPages;

    for (let i = initialPage; i <= pageLimit; i++) {
      pagesButtons.push(
        <button
          key={`page-${i}`}
          type="button"
          className={`btn-paginate ${i === currentPage ? 'btn-active' : ''}`}
          onClick={() => setCurrentPage(i)}>
          {i + 1}
        </button>
      );
    }

    return pagesButtons;
  };

  const renderUsers = () => {
    return visibleUserList.map((user, key) => {
      return (
        <tr key={key}>
          <td className="name">{user.name}</td>
          <td>{user.email}</td>
          <td className="actions">
            <button onClick={() => openAddUserModal(user)} type="button" className="btn-icon">
              <FaPlus />
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <SystemModal modalIsOpen={modalIsOpen} onRequestClose={_onClose} className="sys-modal-large">
      <section className="modal-content modal-import-users">
        <header>
          <h2 className="modal-title">
            {translate('import')} {translate('users')}
          </h2>
        </header>
        <div className="modal-section-content">
          <div className="table-actions">
            <form onSubmit={event => event.preventDefault} className="form-search-menu">
              <div className="content-form">
                <div className="search-box with-borders">
                  <AiOutlineSearch className="search-icon" />
                  <input
                    type="text"
                    className="search-input"
                    name="query"
                    value={searchString}
                    placeholder={translate('dashboard/search')}
                    onChange={onChangeQuery}
                  />
                </div>
              </div>
            </form>
          </div>
          {visibleUserList.length ? (
            <Fragment>
              <table className="users-import">
                <thead>
                  <tr>
                    <th>{translate('name')}</th>
                    <th>{translate('email')}</th>
                    <th>{translate('action')}</th>
                  </tr>
                </thead>
                <tbody>{renderUsers()}</tbody>
              </table>
              <div className="paginate-nav center">
                {currentPage !== 0 && (
                  <button
                    type="button"
                    className="btn-paginate double"
                    onClick={() => setCurrentPage(0)}
                    disabled={currentPage === 0}>
                    <FaChevronLeft />
                    <FaChevronLeft />
                  </button>
                )}
                <button
                  type="button"
                  className="btn-paginate"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 0}>
                  <FaChevronLeft />
                </button>
                {renderPageNumbers()}
                <button
                  type="button"
                  className="btn-paginate"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === numMaxPages}>
                  <FaChevronRight />
                </button>
                {currentPage !== numMaxPages && (
                  <button
                    type="button"
                    className="btn-paginate double"
                    onClick={() => setCurrentPage(numMaxPages)}
                    disabled={currentPage === numMaxPages}>
                    <FaChevronRight />
                    <FaChevronRight />
                  </button>
                )}
              </div>
            </Fragment>
          ) : (
            <h1>{translate('result_not_found')}</h1>
          )}
        </div>
        <footer>
          <button type="button" onClick={_onClose} className="btn-white-green">
            {translate('close')}
          </button>
        </footer>
        <ModalFormUser
          userData={userToAdd}
          modalIsOpen={showAddUserModal}
          onClose={onCloseFormModal}
          roles={roles}
          showSnackBar={showSnackBar}
        />
      </section>
    </SystemModal>
  );
};

export default ModalImportUsers;
