import ICheckboxOption from '../interfaces/ICheckboxOption';
import i18n from '../services/i18n';

export const NO_LICENSE = Symbol('NO_LICENSE');
export const REGISTER_FAILED = Symbol('REGISTER_FAILED');
export const REGISTER_NOT_STARTED = Symbol('REGISTER_NOT_STARTED');
export const REGISTER_IN_PROGRESS = Symbol('REGISTER_IN_PROGRESS');
export const UNPROVISIONED_LICENSE = Symbol('UNPROVISIONED_LICENSE');
export const COMPLETE = Symbol('COMPLETE');

export const userStatus = {
  [NO_LICENSE]: {
    value: -2,
    label: i18n.t('no_license'),
  },
  [REGISTER_NOT_STARTED]: {
    value: -1,
    label: i18n.t('not_started'),
  },
  [REGISTER_FAILED]: {
    value: 0,
    label: i18n.t('failure'),
  },
  [REGISTER_IN_PROGRESS]: {
    value: 1,
    label: i18n.t('in_progress'),
  },
  [UNPROVISIONED_LICENSE]: {
    value: 2,
    label: i18n.t('unprovisioned_license'),
  },
  [COMPLETE]: {
    value: 2,
    label: i18n.t('concluded'),
  },
};

const statusList: ICheckboxOption[] = [
  userStatus[NO_LICENSE],
  userStatus[REGISTER_NOT_STARTED],
  userStatus[REGISTER_FAILED],
  userStatus[REGISTER_IN_PROGRESS],
  userStatus[UNPROVISIONED_LICENSE],
];

export default statusList;
