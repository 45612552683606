import archivfyPT from './pt/archivfy.json';
import archivfyEN from './en/archivfy.json';
import archivfyES from './es/archivfy.json';

const locales = {
  archivfyPT,
  archivfyEN,
  archivfyES,
};

export default locales;
