import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getValidationNumbers } from '../../utils/numbers';
import SystemModal from '../../common/SystemModal';
import { deleteSystemUser } from '../../services/users';

interface IProps {
  userId: string;
  modalIsOpen: boolean;
  onClose(deleted: boolean): void;
  showSnackBar(message: string, type: string): void;
}

const ModalConfirmDelete = ({ userId, modalIsOpen, onClose, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [validationNumbers, setValidationNumbers] = useState<number[]>(getValidationNumbers());
  const [firstNumber, secondNumber] = validationNumbers;
  const validationResult = firstNumber + secondNumber;

  useEffect(() => {
    setValidationNumbers(getValidationNumbers());
    if (!modalIsOpen) {
      setInputValue('');
    }
  }, [userId, modalIsOpen]);

  const onConfirm = async () => {
    try {
      await deleteSystemUser(userId);
      showSnackBar(translate('notification/success_save'), 'success');
      onClose(true);
    } catch (error) {
      console.error(error);
    }
  };

  const _onClose = () => {
    onClose(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <SystemModal modalIsOpen={modalIsOpen} onRequestClose={_onClose}>
      <section className="modal-content modal-delete-registers">
        <header>
          <h2 className="modal-title">{translate('modal/exclude_user_title')}</h2>
        </header>
        <div className="modal-section-content">
          <label className="text-bold">
            {translate('modal/resolve_sum_text')} {firstNumber}+{secondNumber}?
          </label>
          <input value={inputValue} className="form-control" onChange={handleChange} />
        </div>
        <footer>
          <button type="button" onClick={_onClose} className="btn-white-green">
            {translate('cancel')}
          </button>
          <button
            type="button"
            id="btnConfirm"
            onClick={onConfirm}
            disabled={parseInt(inputValue) !== validationResult}
            className="btn-green">
            {translate('users/exclude_confirm1')}
          </button>
        </footer>
      </section>
    </SystemModal>
  );
};

export default ModalConfirmDelete;
