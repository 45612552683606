import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ITenantOu from '../../interfaces/ITenantOu';
import SystemModal from '../../common/SystemModal';
import { createTenantOu } from '../../services/tenant';
import renderFieldHelp, {
  tipForFolderHierarchy,
  tipForPath,
  tipForPermissionaedEmails,
  tipForTeamDriveId,
} from '../../utils/tips';

interface IProps {
  modalIsOpen: boolean;
  onClose(created: boolean): void;
  showSnackBar(message: string, type: string): void;
}

const ModalCreateOu = ({ modalIsOpen, onClose, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = useState<ITenantOu>({
    path: '',
    team_drive_id: '',
    folder_hierarchy: '',
    permissioned_emails: [],
  });

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await createTenantOu(formData);
      if (response.data) {
        setFormData({
          path: '',
          team_drive_id: '',
          folder_hierarchy: '',
          permissioned_emails: [],
        });
        showSnackBar(translate('notification/success_create'), 'success');
        onClose(true);
      }
    } catch (error) {
      showSnackBar(String(error), 'error');
      console.error(error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let new_value: string | string[] = value;
    if (name === 'permissioned_emails') {
      new_value = value.split(',');
    }
    const newFormData = {
      ...formData,
      [name]: new_value,
    };
    setFormData(newFormData);
  };

  const _onClose = () => {
    onClose(false);
  };

  const helptexts = {
    ou_path: tipForPath,
    ou_team_drive_id: tipForTeamDriveId,
    ou_folder_hierarchy: tipForFolderHierarchy,
    ou_permissionaed_emails: tipForPermissionaedEmails,
  };

  return (
    <SystemModal className="modal-ou" modalIsOpen={modalIsOpen} onRequestClose={_onClose}>
      <section className="modal-content">
        <form onSubmit={onSubmit}>
          <header>
            <h2 className="modal-title">
              {translate('edit')} {translate('tenant/ou_page_title')}
            </h2>
          </header>
          <div className="modal-section-content">
            <div className="form-group">
              <label htmlFor="name">{translate('path')}</label>
              <input type="text" className="form-control" name="path" value={formData.path} onChange={handleChange} />
              {renderFieldHelp(['ou_path'], helptexts)}
            </div>
            <div className="form-group">
              <label htmlFor="name">{translate('tenant/ou_team_drive_id')}</label>
              <input
                type="text"
                className="form-control"
                name="team_drive_id"
                value={formData.team_drive_id}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_team_drive_id'], helptexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email">{translate('tenant/ou_folder_hierarchy')}</label>
              <input
                type="text"
                className="form-control"
                name="folder_hierarchy"
                value={formData.folder_hierarchy}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_folder_hierarchy'], helptexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email">{translate('tenant/ou_permissionaed_emails')}</label>
              <input
                type="text"
                className="form-control"
                name="permissioned_emails"
                value={formData.permissioned_emails ? formData.permissioned_emails.join(',') : ''}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_permissionaed_emails'], helptexts)}
            </div>
          </div>
          <footer>
            <button type="button" onClick={_onClose} className="btn-white-green">
              {translate('cancel')}
            </button>
            <button type="submit" className="btn-primary">
              {translate('create')}
            </button>
          </footer>
        </form>
      </section>
    </SystemModal>
  );
};

export default ModalCreateOu;
