import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import { login } from '../../services/auth/index';
import {
  setAccessToken,
  setFirebaseToken,
  setTokenExpire,
  setLocalUser,
  setUserScopes,
} from '../../services/localStorage';
import AppContext from '../../common/AppContext';
import { getTenantConfig } from '../../services/tenant';

interface IProps {
  disabled?: boolean;
  showSnackBar(message: string, type: string): void;
}
const GoogleLogin = ({ disabled, showSnackBar }: IProps): JSX.Element => {
  const history = useHistory();
  const { appLogin } = useContext(AppContext);
  const firebasePopupLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider);
    const { currentUser } = firebase.auth();

    if (currentUser) {
      const { displayName, photoURL, email, uid } = currentUser;
      setLocalUser({
        id: uid,
        email: email || '',
        profile: {
          name: displayName || '',
          image: photoURL || '',
        },
      });

      const firebaseToken = await currentUser.getIdToken();
      setFirebaseToken(firebaseToken);
      setAccessToken(firebaseToken);

      try {
        const loginResponse = await login({ access_token: firebaseToken });
        const {
          data: {
            auth_token: { token, expiration_date, scopes },
          },
        } = loginResponse;

        setUserScopes(scopes);
        setAccessToken(token);
        setTokenExpire(expiration_date);
        appLogin(token);
        const configResponse = await getTenantConfig();
        const { drive_folder_pattern, group_name_pattern } = configResponse.data;
        if (drive_folder_pattern === '' && group_name_pattern === '') {
          history.push('/config');
        }
        history.push('/dashboard');
      } catch (error) {
        showSnackBar(String(error), 'error');
      }
    }
  };

  return (
    <button disabled={disabled} type="button" className="btn-google-login" onClick={firebasePopupLogin}>
      LOGIN
    </button>
  );
};

export default GoogleLogin;
