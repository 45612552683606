import http, { getErrorMessage } from '../http';

import ILoginResponse from '../../interfaces/serviceResponses/ILoginResponse';
import ICustomerResponse from '../../interfaces/serviceResponses/ICustomerResponse';
import IAuthData from '../../interfaces/IAuthData';

const { get, post } = http();

export const login = (data: IAuthData): Promise<ILoginResponse> => {
  return new Promise<ILoginResponse>(async (resolve, reject) => {
    try {
      const response = await post(`/api/auth/token`, data);
      if (response.data.errors.length > 0) {
        throw new Error(getErrorMessage(response.data));
      }
      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCustomerData = (): Promise<ICustomerResponse> => {
  return new Promise<ICustomerResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/auth/token`);

      if (response.data.error || response.data.errors.length) {
        throw new Error(getErrorMessage(response.data));
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export default login;
