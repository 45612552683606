import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface IProps {
  valueStart?: Date | string;
  valueEnd?: Date | string;
  name?: string;
  toggleColor?: string;
  className?: string;
  inputClassName?: string;
  onChange?: (startDate: Date | null, endDate: Date | null, event: ChangeEvent<HTMLInputElement>) => void;
}

const InputDateRange = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { name = '', className = '', inputClassName = '', valueStart, valueEnd, onChange } = props;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (valueStart) {
      let dateValue = null;
      try {
        dateValue = new Date(valueStart.toString());
      } catch (error) {
        console.error(error);
      }
      setStartDate(dateValue);
    }
  }, [valueStart]);

  useEffect(() => {
    if (valueEnd) {
      let dateValue = null;
      try {
        dateValue = new Date(valueEnd.toString());
      } catch (error) {
        console.error(error);
      }
      setEndDate(dateValue);
    }
  }, [valueEnd]);

  const _onChange = (date: Date | [Date, Date] | null, event: ChangeEvent<HTMLInputElement>) => {
    let startDateValue = null;
    let endDateValue = null;
    if (Array.isArray(date)) {
      const [start, end] = date;
      startDateValue = start;
      endDateValue = end;
    } else {
      startDateValue = date;
    }

    if (onChange) {
      if (name) {
        event.target.name = name;
      }
      if (startDateValue) {
        event.target.value = startDateValue?.toISOString();
      }
      onChange(startDateValue, endDateValue, event);
    }
    setStartDate(startDateValue);
    setEndDate(endDateValue);
  };

  const inputId = Date.now();

  return (
    <div className={`input-date-container ${className}`}>
      <DatePicker
        name={name}
        id={`inputDate-${name}-${inputId}-start`}
        selected={startDate}
        dateFormat="P"
        locale="pt-BR"
        selectsStart
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={_onChange}
        className={`${inputClassName ? inputClassName : 'form-control'}`}
      />
      <label className="datetime-placeholder" htmlFor={`inputDate-${name}-${inputId}-start`}>
        {translate('filters/period')}
      </label>
    </div>
  );
};

export default InputDateRange;
