import React, { Fragment, useState, useEffect, useRef, ChangeEvent } from 'react';
import ICheckboxOption, { ICheckedOptions } from '../../../interfaces/ICheckboxOption';

interface IProps {
  optionList: ICheckboxOption[];
  name: string;
  setFilters(options: ICheckedOptions[]): void;
}

type ClickEvent = MouseEvent | TouchEvent;

const FilterDropdown = ({ optionList, name, setFilters }: IProps): JSX.Element => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<ICheckedOptions[]>([]);

  useEffect(() => {
    const checkIfClickedOutside = (e: ClickEvent) => {
      const element = dropDownRef.current;
      if (showDropDown && element && !element.contains(e.target as Node)) {
        setShowDropDown(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [showDropDown]);

  const onChangeFilter = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = event.target;
    let newCheckedOptions = [...checkedOptions];

    if (checked) {
      newCheckedOptions.push({ value: value });
    } else {
      newCheckedOptions = newCheckedOptions.filter(filtro => filtro.value !== value);
    }

    setCheckedOptions(newCheckedOptions);
  };

  const renderDropdown = () => {
    if (showDropDown) {
      return (
        <div className="content-dropdown" ref={dropDownRef}>
          <div className="filter-options">
            {optionList.map((option, key) => (
              <label key={key}>
                <input
                  type="checkbox"
                  value={String(option.value)}
                  checked={checkedOptions.some(filtro => filtro.value === String(option.value))}
                  onChange={onChangeFilter}
                />
                {option.label}
              </label>
            ))}
          </div>
          <button
            type="button"
            onClick={() => {
              setFilters(checkedOptions), setShowDropDown(false);
            }}>
            Aplicar
          </button>
        </div>
      );
    }

    return <Fragment />;
  };

  return (
    <div className="filter-dropdown">
      <button
        type="button"
        className={'filter-name' + (showDropDown ? ' open' : '')}
        onClick={() => setShowDropDown(!showDropDown)}>
        {name}
      </button>
      {renderDropdown()}
    </div>
  );
};

export default FilterDropdown;
