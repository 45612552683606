import firebase from 'firebase/app';
import { setAccessToken, setTokenExpire, userLogout } from '../services/localStorage';
import { login } from '../services/auth/index';

export const refreshAuthToken = async (): Promise<void> => {
  if (firebase.auth) {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      const authToken = await currentUser.getIdToken();
      if (authToken) {
        try {
          const response = await login({ access_token: authToken });
          const {
            auth_token: { expiration_date, tenant_id, token },
          } = response.data;

          if (expiration_date && tenant_id) {
            setAccessToken(token);
            setTokenExpire(expiration_date);
          } else {
            console.log('Erro: não possui Token!');
          }

          if (token) {
            setAccessToken(token);
          } else {
            userLogout();
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }
};

export default refreshAuthToken;
