import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCheckSquare, BiCog, BiExit, BiHome, BiUser, BiColumns, BiIdCard } from 'react-icons/bi';
import { NavLink, useHistory } from 'react-router-dom';
import { userLogout } from '../../services/localStorage';
import { checkPermission } from '../../services/rbac';
import rbacRules from '../../constants/rbacRules';
import AppContext from '../../common/AppContext';

interface IProps {
  isClosing?: boolean;
  isOpening?: boolean;
}

const SideBar = ({ isClosing, isOpening }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const {
    appState: { sidebarLoaded },
    setSidebarLoad,
  } = useContext(AppContext);
  const logout = () => {
    userLogout();
    history.push('/logout');
  };

  useEffect(() => {
    const loadBarTimeout = setTimeout(() => {
      setSidebarLoad(true);
    }, 400);

    return () => {
      clearTimeout(loadBarTimeout);
    };
  }, []);

  const renderActivitiesLink = () => {
    if (checkPermission(rbacRules.audit_list.scopes)) {
      return (
        <li className="item">
          <NavLink exact to="/activities" activeClassName="item-active">
            <BiCheckSquare className="icon" />
            {translate('activities/page_title')}
          </NavLink>
        </li>
      );
    }
    return <Fragment />;
  };

  const renderUsersLink = () => {
    if (checkPermission(rbacRules.user_list.scopes)) {
      return (
        <li className="item">
          <NavLink exact to="/users" activeClassName="item-active">
            <BiUser className="icon" />
            {translate('users/page_title')}
          </NavLink>
        </li>
      );
    }
    return <Fragment />;
  };

  const renderConfigItem = () => {
    if (checkPermission(rbacRules.tenant_config.scopes)) {
      return (
        <li className="item">
          <NavLink exact to="/config" activeClassName="item-active">
            <BiCog className="icon" />
            {translate('tenant/config_page_title')}
          </NavLink>
        </li>
      );
    }
    return <Fragment />;
  };

  const renderOrganizationalUnit = () => {
    if (checkPermission(rbacRules.tenant_ou.scopes)) {
      return (
        <li className="item">
          <NavLink exact to="/ou" activeClassName="item-active">
            <BiColumns className="icon" />
            {translate('tenant/ou_page_title')}
          </NavLink>
        </li>
      );
    }
    return <Fragment />;
  };

  // const renderRolesItem = () => {
  //   if (checkPermission(rbacRules.role_list.scopes)) {
  //     return (
  //       <li className="item">
  //         <NavLink exact to="/roles" activeClassName="item-active">
  //           <BiIdCard className="icon" />
  //           {translate('roles/page_title')}
  //         </NavLink>
  //       </li>
  //     );
  //   }
  //   return <Fragment />;
  // };

  return (
    <nav
      className={`side-bar ${sidebarLoaded ? 'loaded' : ''} ${isClosing ? 'closing' : ''} ${
        isOpening ? 'opening' : ''
      }`}>
      <ul className="listItem">
        <li className="item">
          <NavLink exact to="/dashboard" activeClassName="item-active">
            <BiHome className="icon" />
            Home
          </NavLink>
        </li>
        {renderActivitiesLink()}
        {renderUsersLink()}
        {renderConfigItem()}
        {renderOrganizationalUnit()}
        {/* {renderRolesItem()} */}
        <li className="item">
          <NavLink exact to="/logout" onClick={logout}>
            <BiExit className="icon" />
            {translate('logout')}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default SideBar;
