import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ITenantOu from '../../interfaces/ITenantOu';
import SystemModal from '../../common/SystemModal';
import { getTenantOu, updateTenantOu } from '../../services/tenant';
import tenantInitialOu from '../../constants/tenantInitialOu';
import renderFieldHelp, { tipForFolderHierarchy, tipForPermissionaedEmails, tipForTeamDriveId } from '../../utils/tips';

interface IProps {
  ouData: ITenantOu;
  modalIsOpen: boolean;
  onClose(created: boolean): void;
  showSnackBar(message: string, type: string): void;
}

const ModalEditOu = ({ ouData, modalIsOpen, onClose, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = useState<ITenantOu>(tenantInitialOu);

  useEffect(() => {
    const loadOuSelected = async () => {
      try {
        if (ouData.id) {
          const response = await getTenantOu(ouData.id);
          const [newformData] = response.data;
          setFormData(newformData);
        }
      } catch (error) {
        setFormData(tenantInitialOu);
        console.error(error);
      }
    };
    loadOuSelected();
  }, [ouData]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData && ouData.id) {
      try {
        updateTenantOu(formData, ouData.id);
        showSnackBar(translate('notification/success_save'), 'success');
        onClose(true);
      } catch (error) {
        showSnackBar(String(error), 'error');
        console.error(error);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let new_value: string | string[] = value;
    if (name === 'permissioned_emails') {
      new_value = value.split(',');
    }
    const newFormData = {
      ...formData,
      [name]: new_value,
    };
    setFormData(newFormData);
  };

  const _onClose = () => {
    onClose(false);
  };

  const helptexts = {
    ou_team_drive_id: tipForTeamDriveId,
    ou_folder_hierarchy: tipForFolderHierarchy,
    ou_permissionaed_emails: tipForPermissionaedEmails,
  };

  return (
    <SystemModal className="modal-ou" modalIsOpen={modalIsOpen} onRequestClose={_onClose}>
      <section className="modal-content">
        <form onSubmit={onSubmit}>
          <header>
            <h2 className="modal-title">
              {translate('edit')} {translate('tenant/ou_page_title')}
            </h2>
          </header>
          <div className="modal-section-content">
            <div className="form-group">
              <label htmlFor="name">{translate('tenant/ou_team_drive_id')}</label>
              <input
                type="text"
                className="form-control"
                name="team_drive_id"
                value={formData.team_drive_id}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_team_drive_id'], helptexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email">{translate('tenant/ou_folder_hierarchy')}</label>
              <input
                type="text"
                className="form-control"
                name="folder_hierarchy"
                value={formData.folder_hierarchy}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_folder_hierarchy'], helptexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email">{translate('tenant/ou_permissionaed_emails')}</label>
              <input
                type="text"
                className="form-control"
                name="permissioned_emails"
                value={formData.permissioned_emails ? formData.permissioned_emails.join(',') : ''}
                onChange={handleChange}
              />
              {renderFieldHelp(['ou_permissionaed_emails'], helptexts)}
            </div>
          </div>
          <footer>
            <button type="button" onClick={_onClose} className="btn-white-green">
              {translate('cancel')}
            </button>
            <button type="submit" className="btn-primary">
              {translate('update')}
            </button>
          </footer>
        </form>
      </section>
    </SystemModal>
  );
};

export default ModalEditOu;
