import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IUsersPreviousMounths } from '../../interfaces/serviceResponses/IUserResponse';

interface IProps {
  processedAccounts: IUsersPreviousMounths | undefined;
}

const ListProcessedAccountsContent = ({ processedAccounts }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const renderProcessedAccounts = (): JSX.Element => {
    if (processedAccounts) {
      const keys = Object.keys(processedAccounts);
      const values = Object.values(processedAccounts);
      const thead: JSX.Element[] = [];
      const tbody: JSX.Element[] = [];
      for (let i = 0; i < keys.length; i++) {
        const [year, mounth] = keys[i].split('/');
        const mounthString = new Date(Number(mounth), Number(year) - 1).toLocaleString('default', {
          month: 'long',
        });
        let mounthDOM = <td key={i}>{mounthString}</td>;
        if (keys.length - 1 === i) {
          mounthDOM = (
            <td key={`${i}-${mounth}`}>
              <span>{mounthString}</span>
              <span className="helpBox mounth" data-title={translate('dashboard/count_text')}>
                ?
              </span>
            </td>
          );
        }
        const ucValue = Math.ceil(values[i] / 3);
        const value = (
          <td key={`${i}-${values[i]}`}>
            <span className="helpBox" data-title={`${ucValue} UCs`}>
              {values[i]}
            </span>
          </td>
        );
        thead.push(mounthDOM);
        tbody.push(value);
      }

      return (
        <>
          <thead>
            <tr>{thead}</tr>
          </thead>
          <tbody>
            <tr>{tbody}</tr>
          </tbody>
        </>
      );
    }
    return <Fragment />;
  };

  return (
    <div className="content-dashboard processed-accounts-box">
      <div className="table-dashboard table-list-processed-accounts">
        <table>
          <caption>
            {translate('dashboard/processed_accounts')} / {translate('dashboard/mounth')}
          </caption>
          {renderProcessedAccounts()}
        </table>
      </div>
    </div>
  );
};

export default ListProcessedAccountsContent;
