import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getListTenantOu } from '../../services/tenant';
import ITenantOu from '../../interfaces/ITenantOu';
import tenantInitialOu from '../../constants/tenantInitialOu';
import { checkPermission } from '../../services/rbac';
import rbacRules from '../../constants/rbacRules';
import { MdEdit, MdDelete } from 'react-icons/md';
import ModalEditOu from './ModalEditOu';
import ModalCreateOu from './ModalCreateOu';
import ModalDeleteOu from './ModalDeleteOu';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const TenantOu = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [ouList, setOuList] = useState<ITenantOu[]>([]);
  const [ouSelected, setOuSelected] = useState<ITenantOu>(tenantInitialOu);
  const [showEditOuModal, setShowEditOuModal] = useState(false);
  const [showDeleteOuModal, setShowDeleteOuModal] = useState(false);
  const [showCreateOuModal, setShowCreateOuModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(true);

  useEffect(() => {
    const loadTenantConfig = async () => {
      try {
        const response = await getListTenantOu();
        setOuList(response.data);
      } catch (error) {
        console.error(error);
      }
      setIsSubmiting(false);
    };
    if (isSubmiting) {
      loadTenantConfig();
    }
  }, [isSubmiting]);

  const openEditOuModal = (ou: ITenantOu) => {
    setOuSelected(ou);
    setShowEditOuModal(true);
  };

  const onCloseEditOuModal = (atualized: boolean) => {
    setShowEditOuModal(false);
    setIsSubmiting(atualized);
  };

  const onCloseCreateOuModal = (created: boolean) => {
    setShowCreateOuModal(false);
    setIsSubmiting(created);
  };

  const openDeleteOuModal = (ou: ITenantOu) => {
    setOuSelected(ou);
    setShowDeleteOuModal(true);
  };
  const onCloseDeleteOuModal = (deleted: boolean) => {
    setShowDeleteOuModal(false);
    setIsSubmiting(deleted);
  };

  const renderOuList = () => {
    if (ouList) {
      return ouList.map((ou, key) => {
        const { path } = ou;

        return (
          <tr key={`action-${key}`}>
            <td>{path}</td>
            <td>
              <button type="button" onClick={() => openEditOuModal(ou)}>
                <MdEdit size={16} />
              </button>
              <button type="button" onClick={() => openDeleteOuModal(ou)}>
                <MdDelete size={16} />
              </button>
              {/* <button
                type="button"
                // onClick={() => editUser(key, role_id)}
              >
                <MdDelete size={16} />
              </button> */}
            </td>
          </tr>
        );
      });
    }
  };

  const renderOusContent = () => {
    if (checkPermission(rbacRules.tenant_ou.scopes)) {
      return (
        <div className="content-users">
          <div className="table-list-user">
            <table>
              <thead>
                <tr>
                  <th>{translate('path')}</th>
                  <th>{translate('actions')}</th>
                </tr>
              </thead>
              <tbody>{renderOuList()}</tbody>
            </table>
          </div>
          <ModalEditOu
            ouData={ouSelected}
            modalIsOpen={showEditOuModal}
            onClose={onCloseEditOuModal}
            showSnackBar={props.showSnackBar}
          />
          <ModalCreateOu
            modalIsOpen={showCreateOuModal}
            onClose={onCloseCreateOuModal}
            showSnackBar={props.showSnackBar}
          />
          <ModalDeleteOu
            ouData={ouSelected}
            modalIsOpen={showDeleteOuModal}
            onClose={onCloseDeleteOuModal}
            showSnackBar={props.showSnackBar}
          />
        </div>
      );
    }
    return <Fragment />;
  };

  const renderCreateButton = () => {
    if (checkPermission(rbacRules.tenant_ou.scopes)) {
      return (
        <button type="button" className="btn-primary" onClick={() => setShowCreateOuModal(true)}>
          {translate('create')}
        </button>
      );
    }
    return <Fragment />;
  };

  return (
    <div className="container page-tenant-ou">
      <div className="row">
        <header className="page-header">
          <h2 className="page-title">{translate('tenant/ou_page_title')}</h2>
          {renderCreateButton()}
        </header>
        <div className="page-content">{renderOusContent()}</div>
      </div>
    </div>
  );
};

export default TenantOu;
