import React, { useEffect, useState } from 'react';
import ListUserContent from './ListUsers';
import SearchMenu from './SearchMenu';
import initialUserListFilters from '../../constants/initialUserListFilters';
import { IUserObject, IUsersPreviousMounths } from '../../interfaces/serviceResponses/IUserResponse';
import IUsersFilters from '../../interfaces/IUsersFilters';
import { getUsersList } from '../../services/users';
import { convertFiltersToPayload } from '../../utils/users';
import ListProcessedAccountsContent from './ListProcessedAccounts';
import { useTranslation } from 'react-i18next';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const Dashboard = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [users, setUsers] = useState<IUserObject[]>([]);
  const [paginateParams, setPaginateParams] = useState<IUsersFilters>(initialUserListFilters);
  const [isSubmiting, setIsSubmiting] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [processingUsers, setProcessingUsers] = useState(0);
  const [completedUsers, setCompletedUsers] = useState(0);
  const [processedAccountsAmounths, setprocessedAccountsAmounths] = useState<IUsersPreviousMounths>();

  useEffect(() => {
    const requestUsers = async () => {
      setIsLoading(true);
      try {
        const query = convertFiltersToPayload(paginateParams);
        const response = await getUsersList(query);
        const { users, processing_users, completed_users, total_pages, processed_users_previous_months } =
          response.data;
        const newPaginateParams = {
          ...paginateParams,
          pages: total_pages,
        };
        setIsSubmiting(false);
        setUsers(users);
        setProcessingUsers(processing_users);
        setCompletedUsers(completed_users);
        setPaginateParams(newPaginateParams);
        setprocessedAccountsAmounths(processed_users_previous_months);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setIsSubmiting(false);
      }
    };

    if (isSubmiting) {
      requestUsers();
    }
  }, [paginateParams]);

  const resetSearch = () => {
    setPaginateParams(initialUserListFilters);
  };

  const searchUsers = async (params: IUsersFilters) => {
    try {
      const query = convertFiltersToPayload(params);
      const response = await getUsersList(query);
      const { users, processing_users, completed_users } = response.data;
      setUsers(users);
      setProcessingUsers(processing_users);
      setCompletedUsers(completed_users);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <h2 className="page-title">{translate('home')}</h2>
        <SearchMenu
          searchUsers={searchUsers}
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          setIsSubmiting={setIsSubmiting}
        />
        <ListUserContent
          users={users}
          processingUsers={processingUsers}
          completedUsers={completedUsers}
          resetSearch={resetSearch}
          paginateParams={paginateParams}
          setPaginateParams={setPaginateParams}
          setIsSubmiting={setIsSubmiting}
          showSnackBar={props.showSnackBar}
        />
        <ListProcessedAccountsContent processedAccounts={processedAccountsAmounths} />
        {isLoading && <div className="overlay-loading" />}
      </div>
    </div>
  );
};

export default Dashboard;
