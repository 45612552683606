import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemModal from '../../common/SystemModal';

interface IProps {
  modalIsOpen: boolean;
  onClose(): void;
  onConfirm(): void;
}

const ModalConfirm = ({ modalIsOpen, onClose, onConfirm }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <SystemModal modalIsOpen={modalIsOpen} onRequestClose={onClose}>
      <section className="modal-content modal-delete-registers">
        <header>
          <h2 className="modal-title">{translate('tenant/confirm_save_title')}</h2>
        </header>
        <div className="modal-section-content">
          <p>{translate('tenant/confirm_save_text')}</p>
        </div>
        <footer>
          <button type="button" onClick={onClose} className="btn-white-green">
            {translate('cancel')}
          </button>
          <button type="button" onClick={onConfirm} className="btn-primary">
            {translate('update')}
          </button>
        </footer>
      </section>
    </SystemModal>
  );
};

export default ModalConfirm;
