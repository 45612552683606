import React from 'react';

interface IProps {
  show: boolean;
  message: string;
  type: string;
}

const SnackBar = (props: IProps): JSX.Element => {
  return <div className={'snackbar ' + props.type + (props.show ? ' show' : ' hide')}>{props.message}</div>;
};

export default SnackBar;
