import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-beta.svg';
import GoogleLogin from './GoogleLogin';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const TopBar = (props: IProps): JSX.Element => {
  return (
    <header className="top-bar">
      <div className="logo-box">
        <Link to="/">
          <img src={logo} alt="Logo Ipnet" />
        </Link>
      </div>
      <div className="login">
        <GoogleLogin showSnackBar={props.showSnackBar} />
      </div>
    </header>
  );
};

export default TopBar;
