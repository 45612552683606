import React, { ChangeEvent, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import iconDelete from '../../assets/images/icon-delete.svg';
import iconExport from '../../assets/images/export.svg';
import { IUserObject } from '../../interfaces/serviceResponses/IUserResponse';
import IDeleteUser from '../../interfaces/IDeleteUser';
import IUsersFilters from '../../interfaces/IUsersFilters';
import { getDateString, getTimeString } from '../../utils/date';
import ModalConfirmDelete from './ModalConfirmDelete';
import { getStatusName } from '../../utils/status';
import { userStatus, UNPROVISIONED_LICENSE } from '../../constants/userStatus';
import ModalExportData from './ModalExportData';
import { checkPermission } from '../../services/rbac';
import rbacRules from '../../constants/rbacRules';

interface IProps {
  users: IUserObject[];
  processingUsers: number;
  completedUsers: number;
  resetSearch(): void;
  paginateParams: IUsersFilters;
  setPaginateParams(params: IUsersFilters): void;
  setIsSubmiting(isSubmiting: boolean): void;
  showSnackBar(message: string, type: string): void;
}

const ListUserContent = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { users, processingUsers, completedUsers, resetSearch, paginateParams, setPaginateParams, setIsSubmiting } =
    props;
  const [selectedUsers, setSelectedUsers] = useState<IDeleteUser[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [usersSelectable, setUsersSelectable] = useState<IUserObject[]>([]);

  useEffect(() => {
    const selectable = users.filter(user => user.status === userStatus[UNPROVISIONED_LICENSE].value);
    setUsersSelectable(selectable);
  }, [users]);

  const onChangeUser = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, checked } = event.target;
    let newUsersList = [...selectedUsers];

    if (checked) {
      newUsersList.push({ id: value });
    } else {
      newUsersList = newUsersList.filter(user => user.id !== value);
    }

    setSelectedUsers(newUsersList);
  };

  const toggleAll = (event: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;
    let registers: IDeleteUser[] = [];

    if (checked) {
      registers = usersSelectable.map(user => ({ id: user.user_id }));
    }

    setSelectedUsers(registers);
  };

  const closeModalDelete = (deleted: boolean) => {
    if (deleted) {
      resetSearch();
    }
    setShowDeleteModal(false);
  };

  const setPage = (page: number) => {
    const newPaginateParams = {
      ...paginateParams,
      page,
    };

    setPaginateParams(newPaginateParams);
    setIsSubmiting(true);
  };

  const nextPage = () => {
    const { pages, page } = paginateParams;
    const newPage = Math.min(page + 1, pages);
    if (newPage !== page) {
      setPaginateParams({ ...paginateParams, page: newPage });
      setIsSubmiting(true);
    }
  };

  const prevPage = () => {
    const { page } = paginateParams;
    const newPage = Math.max(page - 1, 1);
    if (newPage !== page) {
      setPaginateParams({ ...paginateParams, page: newPage });
      setIsSubmiting(true);
    }
  };

  const renderProcessedUsers = () => {
    return (
      <div className="users-totals">
        <div className="users-total">
          <span className="number">{processingUsers}</span> {translate('dashboard/users_processing')}
        </div>
        <div className="users-total">
          <span className="number">{completedUsers}</span> {translate('dashboard/license_totals')}
        </div>
      </div>
    );
  };

  const renderList = () => {
    if (users.length) {
      return users.map((userInfo, key) => {
        const {
          email,
          status,
          start_date,
          // upload_pst_td_status,
          // upload_mail_group_status,
          // upload_drive_td_status,
          mail_group_status,
          pst_status,
          drive_status,
          mail_group_percentage,
          last_update,
          end_date,
          user_id,
        } = userInfo;
        let startDateString = '-';
        let startTimeString = '-';
        if (start_date) {
          const startDate = new Date(start_date);
          startDateString = getDateString(startDate);
          startTimeString = getTimeString(startDate);
        }

        let updateDateString = '-';
        let updateTimeString = '-';
        if (last_update) {
          const updateDate = new Date(last_update);
          updateDateString = getDateString(updateDate);
          updateTimeString = getTimeString(updateDate);
        }
        let endDateString = '-';
        let endTimeString = '-';
        if (end_date) {
          const endDate = new Date(end_date);
          endDateString = getDateString(endDate);
          endTimeString = getTimeString(endDate);
        }
        const userChecked = selectedUsers.some(user => user.id == user_id);
        const stringStatus = getStatusName(status, true);
        const backupPst = getStatusName(pst_status);
        const driveStatus = getStatusName(drive_status);
        const emailGroupStatus = getStatusName(mail_group_status);

        const emailStatus = mail_group_percentage && mail_group_status == 1 ? mail_group_percentage : emailGroupStatus;

        const disabledCheckbox = status !== userStatus[UNPROVISIONED_LICENSE].value;

        return (
          <tr key={`action-${key}`}>
            <td>
              <div className="select-row">
                <input
                  type="checkbox"
                  checked={userChecked}
                  disabled={disabledCheckbox}
                  value={user_id}
                  onChange={onChangeUser}
                />
              </div>
            </td>
            <td>{email}</td>
            <td>{stringStatus}</td>
            <td>
              {startDateString}
              <br />
              {startTimeString}
            </td>
            <td>{backupPst}</td>
            <td>{emailStatus}</td>
            <td>{driveStatus}</td>
            <td>
              {updateDateString}
              <br />
              {updateTimeString}
            </td>
            <td>
              {endDateString}
              <br />
              {endTimeString}
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td colSpan={9} className={'text-center text-bold'}>
          Nenhum resultado encontrado.
        </td>
      </tr>
    );
  };

  const renderPageNumbers = () => {
    const { pages, page } = paginateParams;
    const pagesButtons: JSX.Element[] = [];
    let pageLimit = Math.min(Math.ceil(page / 5) * 5, pages);
    let initialPage = Math.max(pageLimit - 4, 1);

    if (pages > initialPage + 4) pageLimit += 1;
    if (initialPage > 5) initialPage -= 1;

    for (let i = initialPage; i <= pageLimit; i++) {
      pagesButtons.push(
        <button
          key={`page-${i}`}
          type="button"
          className={`btn-paginate ${i === page ? 'btn-active' : ''}`}
          onClick={() => setPage(i)}>
          {(i === pageLimit && i > 1) || (i === initialPage && initialPage > 1) ? '...' : i}
        </button>
      );
    }

    return pagesButtons;
  };

  const renderActionDeleteButton = () => {
    if (checkPermission(rbacRules.processed_user_delete.scopes)) {
      return (
        <button
          type="button"
          className="btn-icon"
          disabled={!selectedUsers.length}
          onClick={() => setShowDeleteModal(true)}>
          <img src={iconDelete} alt="Excluir" />
        </button>
      );
    }
    return <Fragment />;
  };

  const allIsChecked = usersSelectable.length > 0 && selectedUsers.length === usersSelectable.length;

  return (
    <div className="content-dashboard list-user-content">
      {renderProcessedUsers()}
      <div className="actions">
        <input type="checkbox" checked={allIsChecked} onChange={toggleAll} />
        {renderActionDeleteButton()}
        <button type="button" className="btn-icon" onClick={() => setShowExportModal(true)}>
          <img src={iconExport} alt="Exportar" />
        </button>
        {selectedUsers.length > 0 && (
          <p>
            {selectedUsers.length} {translate('filters/selected')}
          </p>
        )}
      </div>
      <div className="table-list-user">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>{translate('email')}</th>
              <th>{translate('status')}</th>
              <th>{translate('start')}</th>
              <th>{translate('backup_pst')}</th>
              <th>{translate('group_email')}</th>
              <th>{translate('drive_status')}</th>
              <th>{translate('actualization')}</th>
              <th>{translate('conclusion')}</th>
            </tr>
          </thead>
          <tbody>{renderList()}</tbody>
        </table>
        <ModalConfirmDelete
          usersList={selectedUsers}
          modalIsOpen={showDeleteModal}
          onClose={closeModalDelete}
          showSnackBar={props.showSnackBar}
        />
        <ModalExportData
          usersList={selectedUsers}
          modalIsOpen={showExportModal}
          onClose={() => setShowExportModal(false)}
          showSnackBar={props.showSnackBar}
        />
      </div>
      <div className="paginate-nav">
        <button type="button" className="btn-paginate" onClick={prevPage}>
          <FaChevronLeft />
        </button>
        {renderPageNumbers()}
        <button type="button" className="btn-paginate" onClick={nextPage}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ListUserContent;
