import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router';
import pages from './pages';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import NotFound from '../pages/NotFound';
import Forbidden from '../pages/Forbidden';
import { checkPermission } from '../services/rbac';
import ReactGa from 'react-ga';

const Routes = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    ReactGa.initialize('G-05F1MNLTX7');
  }, []);

  useEffect(() => {
    ReactGa.pageview(location.pathname);
  }, [location]);

  return (
    <Switch>
      {pages.map((route, i) => {
        if (route.auth) {
          if (checkPermission(route.scopes || [])) {
            return <PrivateRoute key={i} {...route} />;
          }
          return <Route component={Forbidden} />;
        }

        return <PublicRoute key={i} {...route} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
