import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IUserForm from '../../interfaces/IUserForm';
import { IGSuiteUser } from '../../interfaces/serviceResponses/IGSuiteUserResponse';
import SystemModal from '../../common/SystemModal';
import { createUserFromGSuite } from '../../services/users';
import { IRoleObject } from '../../interfaces/serviceResponses/IRolesResponse';

interface IProps {
  userData?: IGSuiteUser;
  modalIsOpen: boolean;
  onClose(created: boolean): void;
  roles: IRoleObject[];
  showSnackBar(message: string, type: string): void;
}

const ModalFormUser = ({ userData, modalIsOpen, onClose, roles, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = useState<IUserForm>({
    google_id: '',
    email: '',
    name: '',
    role_id: roles.length ? roles[0].role_id : '',
  });

  useEffect(() => {
    if (userData) {
      const { id, name, email } = userData;
      setFormData({
        google_id: id,
        name,
        email,
        role_id: roles[0].role_id,
      });
    }
  }, [userData]);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData) {
      try {
        createUserFromGSuite(formData);
        showSnackBar(translate('notification/success_create'), 'success');
        onClose(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const selectHandleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const newFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(newFormData);
  };

  const _onClose = () => {
    onClose(false);
  };

  return (
    <SystemModal modalIsOpen={modalIsOpen} onRequestClose={_onClose}>
      <section className="modal-content modal-delete-registers">
        <form onSubmit={onSubmit}>
          <header>
            <h2 className="modal-title">
              {translate('create')} {translate('user')}
            </h2>
          </header>
          <div className="modal-section-content">
            <div className="form-group">
              <label htmlFor="name">{translate('name')}</label>
              <input type="text" className="form-control" name="name" value={formData.name} disabled />
            </div>
            <div className="form-group">
              <label htmlFor="email">{translate('email')}</label>
              <input type="text" className="form-control" name="email" value={formData.email} disabled />
            </div>
            <div className="form-group">
              <label htmlFor="role">{translate('role')}</label>
              <select className="form-control" value={formData.role_id} name="role_id" onChange={selectHandleChange}>
                {roles.map(role => {
                  return (
                    <option key={role.role_id} value={role.role_id}>
                      {role.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <footer>
            <button type="button" onClick={_onClose} className="btn-white-green">
              {translate('cancel')}
            </button>
            <button type="submit" className="btn-primary">
              {translate('create')}
            </button>
          </footer>
        </form>
      </section>
    </SystemModal>
  );
};

export default ModalFormUser;
