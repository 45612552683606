import IUsersFilters, { IUsersFiltersPayload } from '../interfaces/IUsersFilters';

export const convertFiltersToPayload = (filters: IUsersFilters): IUsersFiltersPayload => {
  const payload: IUsersFiltersPayload = {
    page: filters.page,
  };

  if (filters.initial_date) {
    payload.initial_date = filters.initial_date.toISOString();
  }

  if (filters.final_date) {
    payload.final_date = filters.final_date.toISOString();
  }

  if (filters.query) {
    payload.query = filters.query;
  }

  if (filters.status !== undefined) {
    payload.status = filters.status;
  }

  if (filters.domain) {
    payload.domain = filters.domain;
  }

  if (filters.ordered_field) {
    payload.ordered_field = filters.ordered_field;
  }

  if (filters.orderd_desc) {
    payload.orderd_desc = filters.orderd_desc;
  }

  return payload;
};
