import firebase from 'firebase/app';
import IFirebaseConfig from '../interfaces/IFirebaseConfig';

export const getFirebaseSettings = (): IFirebaseConfig => {
  const { FIREBASE_DOMAIN, FIREBASE_API_KEY } = window._env_;

  if (FIREBASE_API_KEY && FIREBASE_DOMAIN) {
    return {
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_DOMAIN,
    };
  }

  return {
    apiKey: '',
    authDomain: '',
  };
};

export const initFirebaseApp = (): void => {
  if (!window.firebaseInstance) {
    window.firebaseInstance = !firebase.apps.length ? firebase.initializeApp(getFirebaseSettings()) : firebase.app();
  }
};
