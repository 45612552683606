import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo-ipnet.png';

const NotFound = (): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <main className="page-not-found">
      <section className="page-section">
        <img src={logo} className="logo" alt="IPNET" />
        <h1>{translate('page_not_found')}</h1>
        <footer className="page-footer">
          <a className="App-link" href="https://www.ipnet.cloud/" target="_blank" rel="noopener noreferrer">
            IPNET - Growth Partner
          </a>
        </footer>
      </section>
    </main>
  );
};

export default NotFound;
