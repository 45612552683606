import React, { ChangeEvent, FormEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ITenantConfig from '../../interfaces/ITenantConfig';
import tenantInitialConfig from '../../constants/tenantInitialConfig';
import { getTenantConfig, updateTenant } from '../../services/tenant';
import ModalConfirm from './ModalConfirm';
import renderFieldHelp from '../../utils/tips';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const TenantConfig = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [formData, setFormData] = useState<ITenantConfig>(tenantInitialConfig);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    const loadTenantConfig = async () => {
      try {
        const response = await getTenantConfig();
        const newFormData = response.data;
        newFormData.group_name_pattern = newFormData.group_name_pattern.replace('@%domain%', '');
        setFormData(newFormData);
      } catch (error) {
        console.error(error);
      }
    };
    loadTenantConfig();
  }, []);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowConfirm(true);
  };

  const onConfirm = async () => {
    const newFormData = { ...formData };
    newFormData.group_name_pattern = newFormData.group_name_pattern.concat('@%domain%');
    try {
      await updateTenant(newFormData);
      props.showSnackBar('Configurações salvas com sucesso', 'success');
      setShowConfirm(false);
    } catch (error) {
      props.showSnackBar(String(error), 'error');
      console.error(error);
    }
  };

  const onCloseModal = () => {
    setShowConfirm(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let newValue = value;
    if (name !== 'drive_folder_pattern') {
      newValue = newValue.replace(' ', '');
    }
    if (name === 'group_name_pattern') {
      newValue = newValue.replace('@', '');
      newValue = newValue.replace('@%domain%', '');
    }
    const newFormData = {
      ...formData,
      [name]: newValue,
    };
    setFormData(newFormData);
  };

  const loginHelp = (
    <p>
      <strong>%login%</strong>: Irá adicionar o nome de usuário ao nome da pasta, ex:
      <br />
      <strong>funcionario_desligado_%login%</strong>
      <br />
      {` irá criar a pasta "funcionario_desligado_jose.silva" para o usuário "jose.silva@empresa.com".`}
      <br />
      Não deixar apenas <strong>%login%@%domain%</strong> no Padrão de Nome no Groups.
    </p>
  );
  const domainHelp = (
    <p>
      <strong>%domain%</strong>: Irá adicionar o domínio da empresa ao nome da pasta, ex:
      <br />
      <strong>%domain%_desligados</strong>
      <br />
      {` irá criar a pasta "empresa.com_desligados" para o domínio "empresa.com".`}
    </p>
  );
  const dateHelp = (
    <p>
      <strong>%date%</strong>: Irá adicionar a data em que foi processado ao nome da pasta, ex:
      <br />
      <strong>desligados_em_%date%</strong>
      <br />
      {` irá criar a pasta "desligados_em_2021-09-03" para o usuário que foi processado no dia 03/09/2021.`}
    </p>
  );

  const helpTexts: { [key: string]: JSX.Element } = {
    '%login%': loginHelp,
    '%domain%': domainHelp,
    '%date%': dateHelp,
  };

  return (
    <div className="container page-tenant-config">
      <div className="row">
        <h2 className="page-title">{translate('tenant/config_page_title')}</h2>
        <div className="page-content">
          <form className="form-tenant-config" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="drive_folder_pattern">{translate('tenant/config_page_drive_folder_pattern')}</label>
              <input
                type="text"
                className="form-control"
                name="drive_folder_pattern"
                value={formData.drive_folder_pattern}
                onChange={handleChange}
              />
              {renderFieldHelp(['%login%', '%domain%', '%date%'], helpTexts)}
            </div>
            <div className="form-group group_name_pattern">
              <label htmlFor="group_name_pattern">{translate('tenant/config_page_group_name_pattern')}</label>
              <input
                type="text"
                className={'form-control' + (formData.group_name_pattern === '%login%' ? ' error' : '')}
                name="group_name_pattern"
                value={formData.group_name_pattern}
                onChange={handleChange}
              />
              {renderFieldHelp(['%login%', '%domain%', '%date%'], helpTexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email_suffix">{translate('tenant/config_page_email_suffix')}</label>
              <input
                type="text"
                className="form-control"
                name="email_suffix"
                value={formData.email_suffix}
                onChange={handleChange}
              />
              {renderFieldHelp(['%date%'], helpTexts)}
            </div>
            <div className="form-group">
              <label htmlFor="email_prefix">{translate('tenant/config_page_email_prefix')}</label>
              <input
                type="text"
                className="form-control"
                name="email_prefix"
                value={formData.email_prefix}
                onChange={handleChange}
              />
              {renderFieldHelp(['%date%'], helpTexts)}
            </div>
            <button type="submit" className="btn-primary" disabled={formData.group_name_pattern === '%login%'}>
              Salvar
            </button>
          </form>
          <ModalConfirm modalIsOpen={showConfirm} onClose={onCloseModal} onConfirm={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default TenantConfig;
