import IUser from '../interfaces/IUser';

export const getLocalUser = (): IUser => {
  const user: IUser = {
    id: '',
    email: '',
    profile: {
      name: '',
      image: '',
    },
  };

  const localUserData = localStorage.getItem('userData');

  if (localUserData) {
    const userData = JSON.parse(localUserData);
    user.id = userData.id || '';
    user.email = userData.email || '';
    user.profile.name = userData.profile.name || '';
    user.profile.image = userData.profile.image || '';
  }
  return user;
};

export const setLocalUser = (userData: IUser): IUser => {
  const localUserData = JSON.stringify(userData);
  localStorage.setItem('userData', localUserData);
  return userData;
};

export const getEmailLocalUser = (): string => {
  const localUserData = localStorage.getItem('userData');
  let email = '';
  if (localUserData) {
    const userData = JSON.parse(localUserData);
    email = userData.email;
  }
  return email;
};

export const setTokenExpire = (token_expires: string): void => {
  if (token_expires) {
    const loopDelaySeconds = 120;
    const tokenDate = new Date(token_expires);
    tokenDate.setSeconds(-loopDelaySeconds);
    localStorage.setItem('token_expires', tokenDate.getTime().toString());
  } else {
    const dateNow = new Date();
    localStorage.setItem('token_expires', dateNow.getTime().toString());
  }
};

export const getTokenExpire = (): string => {
  const token_expires = localStorage.getItem('token_expires');
  if (token_expires) {
    return token_expires;
  }
  const dateNow = new Date();
  return dateNow.getTime().toString();
};

export const userLogout = (): boolean => {
  localStorage.removeItem('userData');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('firebaseToken');
  return true;
};

export const getAccessToken = (): string => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return accessToken;
  }
  return '';
};

export const setAccessToken = (accessToken: string): void => {
  localStorage.setItem('accessToken', accessToken);
};

export const getFirebaseToken = (): string => {
  const firebaseToken = localStorage.getItem('firebaseToken');

  if (firebaseToken) {
    return firebaseToken;
  }
  return '';
};

export const setFirebaseToken = (accessToken: string): void => {
  localStorage.setItem('firebaseToken', accessToken);
};

export const authTokenIsValid = (): boolean => {
  const token_expires = window.localStorage.getItem('token_expires');

  if (!token_expires) {
    return false;
  } else {
    const dateNow = new Date();
    const tokenExpires = new Date(parseInt(token_expires, 10));
    return tokenExpires > dateNow;
  }
};

export const setUserScopes = (userScopes: string[]): void => {
  localStorage.setItem('scopes', JSON.stringify(userScopes));
};

export const getUserScopes = (): string[] => {
  let scopes = [];
  const userScopes = localStorage.getItem('scopes');
  if (userScopes) {
    scopes = JSON.parse(userScopes);
  }
  return scopes;
};
