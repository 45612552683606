import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { AppProvider } from './common/AppContext';
import { initialAppState } from './common/AppContext';
import AuthMonitor from './common/AuthMonitor/';
import i18n from './services/i18n';
import { initFirebaseApp } from './utils/firebase';
import Routes from './Routes';

declare global {
  interface Window {
    _env_: {
      API_URL: string;
      FIREBASE_DOMAIN: string;
      FIREBASE_API_KEY: string;
      FIREBASE_AUTH_CLIENT_ID: string;
    };
    firebaseInstance?: firebase.app.App;
  }
}

const App = (): JSX.Element => {
  const [appLoading, setAppLoading] = useState(true);

  useEffect(() => {
    const checkLogin = async () => {
      setAppLoading(true);
      initFirebaseApp();
      registerLocale('pt-BR', ptBR);
      setAppLoading(false);
    };

    checkLogin();
  }, []);

  if (appLoading) {
    return (
      <main>
        <div className="overlay-loading" />
      </main>
    );
  }

  return (
    <I18nextProvider i18n={i18n}>
      <AppProvider initialState={initialAppState}>
        <AuthMonitor />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppProvider>
    </I18nextProvider>
  );
};

export default App;
