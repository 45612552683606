import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IDeleteUser from '../../interfaces/IDeleteUser';
import { exportUsers } from '../../services/users';
import SystemModal from '../../common/SystemModal';

interface IProps {
  usersList: IDeleteUser[];
  modalIsOpen: boolean;
  onClose(deleted: boolean): void;
  showSnackBar(message: string, type: string): void;
}

const ModalExportData = ({ usersList, modalIsOpen, onClose, showSnackBar }: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [exportType, setExportType] = useState('csv');
  const [keepInfo, setKeepInfo] = useState(false);

  const onConfirm = async () => {
    try {
      const response = await exportUsers(usersList, exportType, inputValue);
      if (response) {
        const fileData = response;
        const endType = exportType === 'csv' ? 'csv' : 'xlsx';
        let url = window.URL.createObjectURL(new Blob([fileData], { type: 'text/csv' }));
        if (endType !== 'csv') {
          url = window.URL.createObjectURL(
            new Blob([fileData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' })
          );
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${inputValue}.${endType}`);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode) link.parentNode.removeChild(link);
        showSnackBar(translate('notification/success_export_users'), 'success');
      }
      onClose(true);
    } catch (error) {
      showSnackBar(String(error), 'error');
      console.error(error);
    }
  };

  const _onClose = () => {
    onClose(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.type;
    if (type === 'radio') {
      setExportType(e.target.name);
    } else if (type === 'checkbox') {
      setKeepInfo(!keepInfo);
    } else {
      setInputValue(e.target.value);
    }
  };

  return (
    <SystemModal modalIsOpen={modalIsOpen} onRequestClose={_onClose} className={'alt-export-data'}>
      <section className="modal-content modal-export-registers">
        <header>
          <h2 className="modal-sub-title">{translate('modal/export_info_title')}</h2>
        </header>
        <div className="modal-section-content">
          <input
            type="text"
            placeholder={translate('modal/export_info_file_name')}
            value={inputValue}
            onChange={e => handleChange(e)}
          />
        </div>
        <div className="modal-content-radio">
          <p className="modal-sub-title">{translate('modal/export_info_subtitle')}</p>
          <label>
            <input type="radio" name="csv" id="csv" checked={exportType === 'csv'} onChange={e => handleChange(e)} />
            CSV
          </label>
          <label>
            <input
              type="radio"
              name="csv-excel"
              id="csv-excel"
              checked={exportType === 'csv-excel'}
              onChange={e => handleChange(e)}
            />
            CSV(Excel)
          </label>
          {/* <label>
            <input
              type="radio"
              name="googleSheet"
              id="googleSheet"
              checked={exportType === 'googleSheet'}
              onChange={e => handleChange(e)}
            />
            {translate('modal/export_info_google')}
          </label> */}
        </div>
        {/* <div className="modal-content-checkbox">
          <p className="modal-sub-title">{translate('modal/export_info_subtitle1')}</p>
          <label>
            <input type="checkbox" name="option" id="option" checked={keepInfo} onChange={e => handleChange(e)} />
            {translate('modal/export_info_text')}
          </label>
        </div> */}
        <footer>
          <button type="button" onClick={_onClose} className="btn-white-green">
            {translate('modal/export_info_cancel')}
          </button>
          <button type="button" id="btnConfirm" onClick={onConfirm} className="btn-green" disabled={inputValue === ''}>
            {translate('modal/export_info_confirm')}
          </button>
        </footer>
      </section>
    </SystemModal>
  );
};

export default ModalExportData;
