import React, { Fragment } from 'react';

const renderFieldHelp = (wildcards: string[], helpTexts: { [key: string]: JSX.Element }): JSX.Element => {
  const helpTextsElements = wildcards.map((wildcard, key) => (
    <Fragment key={`${key}-${wildcard}`}>{helpTexts[wildcard]}</Fragment>
  ));

  return (
    <div className="field-help">
      <h3>Coringas disponíveis:</h3>
      {helpTextsElements}
    </div>
  );
};

export const tipForPath = (
  <p>
    <strong>Path</strong>: Deve iniciar com / e não possuir espaços. Caso a organização possua mais de um domínio, deve
    se colocar o nome do domínio antes do nome da unidade organizacional. Exemplos:
    <br />
    <strong>/Desligados</strong>
    <br />
    ou
    <br />
    <strong>/exemplo.com/Desligados.</strong>
  </p>
);

export const tipForTeamDriveId = (
  <p>
    <strong>Id Team Drive</strong>: Id do diretório compartilhado onde irão os dados dos usuários arquivados.
    <br />
  </p>
);

export const tipForFolderHierarchy = (
  <p>
    <strong>Hierarquia da pasta</strong>: Caso queira criar pastas para dividir os dados dos usuarios arquivados por
    mês, colocar <strong>%month%</strong>.
    <br />
  </p>
);

export const tipForPermissionaedEmails = (
  <p>
    <strong>Emails permitidos</strong>: Quais emails serão administradores dos Google Groups criados para os emails dos
    usuários arquivados.
    <br />
  </p>
);

export default renderFieldHelp;
