import IActivitieResponse from '../../interfaces/IActivitieResponse';
import { IActivitiesFiltersPayload } from '../../interfaces/IActivitiesFilters';
import http, { getErrorMessage } from '../http';

const { get } = http();

export const getAuditLog = (query: IActivitiesFiltersPayload): Promise<IActivitieResponse> => {
  return new Promise<IActivitieResponse>(async (resolve, reject) => {
    const options = {
      params: {
        ...query,
      },
    };

    try {
      const response = await get(`/api/audit`, options);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};
