import React from 'react';
import { useHistory } from 'react-router';
import logo from '../../assets/images/customer_logo.png';

// prettier-ignore
const UseTerms = (): JSX.Element => {
  const history = useHistory();
  return (
    <div className="container page-use-terms">
      <div className="row">
        <header className="institutional page-header">
          <img src={logo} alt="Logo Ipnet" />
          <h2 className="page-title">Termos de Uso</h2>
        </header>
        <div className="page-content">
          <div className="page-text-box content-use-terms">
            <p>
              Prezado (a)
              <br />
              Estes Termos de Uso disciplinarão a relação entre o <strong>Archivfy</strong> e <strong>Vocês</strong>.
              <br />
              Para utilizar os serviços da Plataforma, <strong>Você</strong> deverá concordar e aceitar os Termos de Uso
              e a Política de Privacidade.
              <br />
            </p>
            <h2 className="text-upper">Condições gerais</h2>
            <p>
              <strong>1.1 Âmbito</strong>. Os presentes Termos e Condições Gerais de Uso (denominado somente &quot;Termos de Uso&quot; ou &quot;Termos&quot;) vinculam todas as atividades desenvolvidas e serviços oferecidos por <strong>Nós</strong> (doravante assim denominada ou somente &quot;Plataforma&quot;), que engloba o website e seus subdomínios, aplicativos, programas e demais extensões. A Plataforma é de propriedade da <strong>IPNET SERVIÇOS EM NUVEM E DESENVOLVIMENTO DE SISTEMAS LTDA.</strong>, sociedade inscrita no CNPJ sob o nº 32.578.382/0001-21, com sede na Rua Siqueira Campos, n° 43, cobertura 1 - Parte, Copacabana, Rio de Janeiro/RJ, CEP: 22031-071, e está sob sua responsabilidade e gestão.
            </p>
            <p>
              <strong>1.2. Atuação</strong>. <strong>Nós</strong> somos uma Plataforma que realiza arquivamento de e-mails e drives de usuários Google Cloud Plataform, que efetua a transferência desses arquivos de acordo com as configurações parametrizadas pelo Administrador do sistema, efetuando o desprovisionamento da licença vinculada ao usuário arquivado. Todas as APIs utilizadas por <strong>Nós</strong> pertencem ao fornecedor Google Cloud Platform, que garante a segurança, conformidade e rastreabilidade dos serviços. A <strong>IPNET</strong> é uma  parceira premier do Google na América Latina e o <strong>Archivfy</strong> foi criado a partir de nossa expertise em Google Cloud Platform.
            </p>
            <p>
              <strong>1.3. Sujeitos</strong>. Utilizaremos a expressão <strong>&quot;Vocês&quot;</strong> para nos referirmos às empresas que utilizam a Google Cloud Platform e contratam o <strong>Archivfy.</strong>
            </p>
            <p>
              <strong>1.4. Adesão e Aceite</strong>. Pelo presente instrumento, <strong>Vocês</strong> concordam que:
              <br />
            </p>
            <ul>
              <li>
                (i) Deverão aceitar nossos Termos de Uso e Política de Privacidade, para fins de cadastro, controle de acesso e execução de melhorias na Plataforma; 
              </li>
              <li>
                (ii) Clicando na caixa de aceitação dos Termos devem aderir e concordar em se submeter ao disposto nos presentes Termos;
              </li>
            </ul>
            <p>
              <strong>1.5. Violação das Condições</strong>. Na eventualidade de <strong>Você</strong> descumprir qualquer das condições aqui previstas, <strong>Nós</strong> nos reservamos o direito de encerrar suas respectivas contas e suspender ou anular seu acesso à Plataforma, sem necessidade de aviso prévio.
            </p>
            <p>
              <strong>1.6. Modificações</strong>. <strong>Nós</strong> nos reservamos o direito de, a qualquer momento, modificar os Termos de Uso, sempre com o envio de avisos para sua ciência, sem qualquer responsabilização da <strong>IPNET</strong> devido a:
              <br />
              <span className="sub-paragraph">
                <strong>1.6.1.</strong> Quaisquer modificações efetuadas por <strong>Nós</strong> na plataforma, que entrarão em vigor imediatamente após sua publicação online.
              </span>
            </p>

            <h2 className="text-upper">Modo de utilização</h2>
            <p>
              <strong>2.1. Dinâmica</strong>. <strong>Vocês</strong> poderão se cadastrar em <strong>Nossa</strong> Plataforma fornecendo as informações conforme previsto na Política de Privacidade e associando a conta Google de sua empresa. Usuários secundários poderão ser cadastrados pelo responsável pela empresa. Uma vez cadastrados, <strong>Vocês</strong> poderão acessar a Plataforma utilizando o login no Google.
            </p>
            <p>
              <strong>2.2. Incorreção de Informações Cadastrais.</strong> <strong>Você</strong>, para utilizar os Serviços da Plataforma, deverá prestar as informações exigidas no momento do cadastro, conforme disposto na Política de Privacidade.
              <br />
              <span className="sub-paragraph">
                <strong>2.2.1. Nós </strong>nos reservamos o direito de impedir, a <strong>Nosso</strong> critério, novos cadastros, ou cancelar os já efetuados, em caso de ser detectada anomalia que considere, em <strong>Nossa</strong> análise, revestida de erro grave ou demonstre tentativa deliberada de burlar as regras aqui descritas, obrigatórias para todos <strong>Vocês.</strong>
              </span>
            </p>
            <p>
              <strong>2.3. Uso de Cadastro por Terceiros</strong>. Os cadastros na plataforma são individuais e intransferíveis, devendo a <strong>IPNET</strong> ser imediatamente notificada sobre qualquer uso não autorizado de sua conta, em tempo hábil, não sendo <strong>Nós</strong> responsáveis por quaisquer prejuízos ou danos oriundos do uso de &quot;login&quot; e senha por terceiro, com ou sem o consentimento do usuário.
              <br />
              <span className="sub-paragraph">
                <strong>2.3.1. Vocês</strong> deverão: (i) Ser cuidadosos com os dados de sua identificação individual sempre que acessarem a Internet; (ii) Tomar outras medidas necessárias para se protegerem de danos, inclusive fraudes online e off-line; (iii) Ser diligentes no uso do conteúdo disponibilizado na <strong>Nossa </strong>Plataforma, sabendo de suas responsabilidades; e (iv) Cumprir rigorosamente todas as determinações destes Termos de Uso. 
              </span>
            </p>
            <p>
              <strong>2.4. Obrigações de Vocês</strong>. É de obrigação de <strong>Vocês</strong>:
            </p>
            <ul>
              <li>a) Não utilizar a <strong>Nossa </strong>Plataforma para qualquer propósito que seja ilícito ou proibido pelos termos de uso e/ou pelas normas aplicáveis;</li>
              <li>b) Autorizar que <strong>Nós</strong> possamos enviar comunicações por e-mail, telefone ou mensagens instantâneas, inclusive de caráter publicitário;</li>
              <li>c) Apresentar comportamento honesto e de boa fé;</li>
              <li>d) Informar corretamente e com precisão todos os seus dados, para fins cadastrais.</li>
            </ul>
            <p>
              <strong>2.5. Obrigações da Plataforma</strong>. É <strong>Nossa </strong>obrigação:
            </p>
            <ul>
              <li>a) Disponibilizar os serviços da Plataforma de forma segura e estável;</li>
              <li>b) Manter, segundo seu julgamento e possibilidades de mercado, a atualização constante da plataforma visando seu aperfeiçoamento e adaptação às novas tecnologias disponíveis;</li>
              <li>c) Proteger a confidencialidade de todas as informações de <strong>Vocês</strong>.</li>
            </ul>
            <p>
              <strong>2.6. Idoneidade</strong>. <strong>Vocês</strong> declaram, ao consentir com este documento para utilizar a Plataforma, que detém idoneidade íntegra, ficando ciente, desde já, que as informações cadastrais falsas ou inverídicas registradas ou fornecidas durante o processo eletrônico de contratação de serviços podem constituir crime.
            </p>
            <p>
              <strong>2.7. Proibições</strong>. É proibido que <strong>Vocês</strong>:
            </p>
            <ul>
              <li>a) Utilizem qualquer vírus, worm, cavalo de Tróia, time bomb, spyware ou outro código, arquivo ou programa de computador que seja prejudicial ou invasivo ou que possa ser ou seja destinado a danificar, sequestrar, desabilitar, interferir com, ou perturbar a operação de, ou monitorar o uso de qualquer hardware, software ou equipamento;</li>
              <li>b) Utilizem qualquer material que dê origem a responsabilidade civil ou criminal, que encoraje conduta que constitui uma ofensa criminal ou que encoraje ou forneçam informações instrutivas sobre atividades ilegais ou atividades como hacking e cracking;</li>
              <li>c) Utilizem a Plataforma para qualquer finalidade ilegal ou fraudulenta;</li>
              <li>d) Interfiram ou perturbem a operação da Plataforma ou violem quaisquer exigências, procedimentos, políticas ou regulamentos do <strong>Archivfy</strong>;</li>
              <li>e) Restrinjam ou inibam qualquer outra pessoa de utilizar a Plataforma;</li>
              <li>f) Removam qualquer aviso de direitos autorais, de marca comercial ou outros direitos de propriedade da <strong>IPNET</strong> ou materiais provenientes da Plataforma.</li>
            </ul>
            <h2 className="text-upper">Acesso e Restrições de funcionalidade</h2>
            <p>
              <strong>3.1. Restrições</strong>. Não é permitido acessar <strong>Nossas</strong> áreas de programação, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de webmastering, realizar ou permitir que se realize qualquer tipo de engenharia reversa, tradução, decompilação, cópia, modificação, reprodução, locação, sublocação, sublicenciamento, publicação, divulgação, transmissão, empréstimo, distribuição ou, de qualquer outra forma, a disposição de ferramentas de consulta deste website e de suas funcionalidades para terceiros sem a nossa prévia e expressa autorização, ficando sujeito quem o fizer a penalidades da legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem. Esta restrição inclui qualquer tentativa de incorporar quaisquer informações da plataforma em qualquer outro diretório, produto ou serviço.
            </p>
            <p>
              <strong>3.2. Versão Beta</strong>. A plataforma se encontra em versão beta, podendo deixar de possuir funcionalidades ou receber novas sem aviso prévio. Pode apresentar instabilidades, perdas de dados, erros de processamento que serão corrigidos sem correspondência a um SLA padrão. Após a homologação da plataforma, será necessária renegociação para continuar com a plataforma.
            </p>
            <h2 className="text-upper">Remuneração e Cancelamento</h2>
            <p>
              <strong>4.1. Remuneração.</strong> Para utilizar as funcionalidades da Plataforma, <strong>Vocês</strong> não precisarão efetuar nenhuma contraprestação pecuniária. 
            </p>
            <p>
              <strong>4.2. Cancelamento por Você.</strong> Caso <strong>Você</strong> cancele seu cadastro na Plataforma, todos os seus registros, políticas e históricos serão excluídos, ressalvadas as hipóteses em que haja a necessidade de retenção dos registros para cumprimento de obrigação legal ou regulatória.
            </p>
            <h2 className="text-upper"><strong>Exclusão de garantias</strong></h2>
            <p>
              <strong>5.1. Disponibilidade.</strong> A plataforma está disponível para uso de <strong>Vocês</strong> e é oferecida &quot;no estado em que se encontra&quot; e &quot;conforme disponível&quot;. <strong>Nós</strong> declinamos qualquer responsabilidade por garantias implícitas de comerciabilidade, adequação a um determinado fim e de retorno financeiro.  Além disso, <strong>Nós</strong> não garantimos que os serviços serão fornecidos sem interrupções ou erros. <strong>Você</strong> aceita que todo o risco decorrente da utilização da nossa Plataforma é da sua exclusiva responsabilidade.
            </p>
            <h2 className="text-upper">Limitação de responsabilidade</h2>
            <p>
              <strong>6.1. Nós</strong> responderemos exclusiva e limitadamente pelos serviços por si prestados
              <br />
              <span className="sub-paragraph">
                <strong>6.1.1. Nós</strong> reiteramos que não constituímos com nenhum de <strong>Vocês</strong>: (i) sociedade, associação ou representação; (ii) prestação de qualquer serviço diverso do objeto deste instrumento particular, e/ou (iii) relação empregatícia.
              </span>
            </p>
            <p>
              <strong>6.2. Responsabilidade. Nós</strong> não seremos responsáveis por quaisquer perdas ou danos sofridos, resultantes de: (i) qualquer informação errada ou incompleta que seja fornecida por Você; (ii) qualquer fraude, declaração fraudulenta ou violação do dever, ou violação de qualquer uma das condições por <strong>Você</strong>; (iii) falhas nas conexões; (iv) problemas no processamento de dados por culpa de terceiros; (v) produtos ou serviços de terceiros, mesmo que adquiridos através da Plataforma.
            </p>
            <p>
              <strong>6.3. Violação de Direito de Terceiros. Nós</strong> não nos responsabilizamos perante <strong>Vocês</strong> em relação a qualquer violação de direito de terceiros.
            </p>
            <p>
              <strong>6.4. Danos.</strong>  Em nenhum caso, <strong>Nós</strong> seremos responsáveis por danos indiretos, incidentais, especiais, exemplares, punitivos ou consequentes, incluindo, danos pessoais ou à propriedade, relacionados com, ou de outra forma resultantes, de qualquer uso da plataforma que Você possa sofrer devido às ações realizadas ou não realizadas através desta, ainda que decorrentes da conduta de terceiros.
            </p>
            <p>
               <strong>6.5. Indenização. Vocês</strong> indenizarão a <strong>IPNET</strong>, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por terceiros decorrentes de suas atividades na Plataforma, e pelo descumprimento dos termos de uso e condições e demais políticas da mesma, e ainda pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.
            </p>
            <h2 className="text-upper">Inativação do cadastro, dúvidas e sugestões </h2>
            <p>
              <strong>7.1. Inativação do Cadastro, Dúvidas e Sugestões.</strong> Em caso de inativação da conta, dúvida ou sugestões sobre nossos Termos de Uso ou qualquer outra informação, <strong>Você</strong> poderá entrar em contato conosco através do e-mail <strong><a href="mailto:suporte_apps@ipnet.cloud" target="_blank" rel="noopener noreferrer">suporte_apps@ipnet.cloud</a></strong> Ou por qualquer contato descrito no site <strong><a href="https://ipnet.cloud/" target="_blank" rel="noopener noreferrer">https://ipnet.cloud/</a></strong>
            </p>
            <p>
              <strong>7.2. Vedação e inativação de cadastro.</strong> Como forma de manter a confiança e respeito nesta Plataforma, serão vedados atos ilegais ou incompatíveis com a proposta de zelar pela prestação dos serviços de qualidade. Assim, poderá resultar em suspensão ou inativação do seu cadastro se <strong>Você</strong> incorrer em qualquer das hipóteses elencadas abaixo: 
            </p>
            <ul>
              <li>(i) Promoção da violência e/ou discriminação, baseada em questões de raça, sexo, religião, nacionalidade, orientação sexual ou de qualquer outro tipo;</li>
              <li>(ii) Violação de quais leis, principalmente no que tange aos direitos autorais, propriedade intelectual, tais como cópias não autorizadas, utilização de imagens, sons, movimentos ou textos sem autorização do autor, sejam elas marcas, réplicas e/ou falsificações;</li>
              <li>(iii) Violação do disposto na Política de Privacidade da Plataforma. </li>
            </ul>
            <h2 className="text-upper">Engenharia da plataforma</h2>
            <p>
              <strong>8.1. Aperfeiçoamento. Vocês</strong> concordam que todo e qualquer sistema, plataforma ou serviço, independentemente de seu produtor ou características, é uma espécie de produto que está sempre em atualização e aperfeiçoamento.
            </p>
            <p>
              <strong>8.2. Erros de Funcionamento.</strong> Eventuais erros no funcionamento da Plataforma serão corrigidos o mais breve possível, durante o período que for necessário para manutenção. <strong>Nós</strong> não pretendemos que o funcionamento do servidor da Plataforma, bem como de seu sistema, do seu banco de dados, software e de seu website, sejam livres de erros, falhas ou de interrupções.
            </p>
            <h2 className="text-upper">Propriedade intelectual</h2>
            <p>
              <strong>9.1. Utilização da Propriedade Intelectual.</strong> O uso comercial da expressão &quot;<strong>Archivfy</strong>&quot;, como marca, nome empresarial ou nome de domínio, e o logotipo são de propriedade exclusiva dos <strong>Nossos</strong> proprietários, conforme disposto no respectivo ato constitutivo da organização. Da mesma forma, direitos autorais e outros direitos passíveis de proteção por propriedade industrial como estrutura, os conteúdos das telas relativas à Plataforma, assim como os programas relacionados a mesma, software, website, bancos de dados, redes e arquivos. Todos os direitos estão protegidos, no Brasil e internacionalmente por leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. Aos <strong>Nossos</strong> proprietários reservam-se todos os direitos relativos a autorias, marcas, patentes, modelos e desenhos industriais, quer sejam de sua propriedade ou licenciados em seu favor, a si.
              <br />
              <span className="sub-paragraph">
                <strong>9.1.1.</strong> Não é permitido reproduzir, duplicar, copiar, vender, revender, visitar, ou de qualquer outra forma explorar para fins comerciais o conteúdo da Plataforma sem o prévio consentimento escrito dos <strong>Nossos</strong> proprietários. Particularmente, é expressamente proibida a utilização de mineração de dados, robôs, ou outras ferramentas de coleta e extração de dados para extrair, de forma isolada ou recorrente, qualquer parte substancial da Plataforma visando sua reutilização.
              </span>
            </p>
            <p>
              <strong>9.2. Relações com Terceiros.</strong> A Plataforma poderá fornecer links de acesso a outros websites, o que não significa que esses sites sejam de propriedade ou operados por <strong>Nós</strong>. Como não possuímos controle sobre esses sites, <strong>Nós</strong> não seremos responsáveis pelos conteúdos, práticas e serviços ofertados nos mesmos. A presença de links para outros sites não implica relação de sociedade, de supervisão, de cumplicidade ou solidariedade da <strong>IPNET</strong> para com esses sites e seus conteúdos.
            </p>
            <h2 className="text-upper">Condições finais</h2>
            <p>
              <strong>10.1. Suspensão e Cancelamento do Acesso.</strong> Na eventualidade de um descumprimento de qualquer disposição dos Termos de Uso, <strong>Você</strong> reconhece e aceita que <strong>Nós</strong> podemos, sem aviso prévio, interromper ou suspender, temporária ou permanentemente, parte ou a totalidade, do acesso à plataforma.
            </p>
            <p>
              <strong>10.2. Advertências e suspensão.</strong> Sem prejuízo de outras medidas cabíveis, <strong>Nós</strong> poderemos advertir, suspender, temporária ou definitivamente, seu acesso, a qualquer tempo, se:
            </p>
            <ul>
              <li>a)	não cumprir com qualquer dispositivo dos termos de uso ou da política de privacidade;</li>
              <li>b)	descumprir com quaisquer de suas obrigações ou proibições assumidas ao utilizar os serviços da plataforma;</li>
              <li>c)	utilizar, sem autorização, nome e/ou marca da <strong>IPNET</strong>, em parte ou na sua totalidade, e violar direitos de propriedade intelectual da <strong>IPNET</strong>;</li>
              <li>d)	não puder ser verificada a identidade ou se qualquer informação fornecida por este estiver incorreta.</li>
            </ul>            
            <p>
              <strong>10.3. Tratamento dos Dados Pessoais.</strong> Para que a Plataforma tenha qualidade e para que <strong>Vocês</strong> possam obter resultados de forma rápida e segura, é necessário o fornecimento de dados pessoais, nos termos dispostos na Política de Privacidade do <strong>Archivfy</strong>.
            </p>
            <p>
              <strong>10.4. Nulidades.</strong> Se qualquer cláusula deste instrumento for considerada ilegal, inválida ou inaplicável, no seu todo ou em parte, ao abrigo de qualquer lei, essa cláusula ou parte do mesmo será nesta exata medida entendida como não fazendo parte dos Termos de Uso, sendo que a legalidade, validade e aplicabilidade das demais cláusulas não serão afetadas.
            </p>
            <h2 className="text-upper">Lei aplicável e jurisdição</h2>
            <p>
              <strong>11.1.</strong> Os presentes Termos de Uso são regidos pelas leis brasileiras, tendo como definido o foro da Comarca Central do Rio de Janeiro como competente para dirimir eventuais controvérsias oriundas destes, em prejuízo de qualquer outro, por mais privilegiado que seja ou venha a ser, devendo-se dar prioridade, sempre, às tentativas de resolução por conciliação ou mediação.
            </p>
            <p>
              Criado em 06 de Julho de 2021.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UseTerms;
