import IRolesResponse, { IRoleObject, IScopesResponse } from '../../interfaces/serviceResponses/IRolesResponse';
import http, { getErrorMessage } from '../http';

const { get, post, patch, delete: deleteRequest } = http();

export const getRoles = (): Promise<IRolesResponse> => {
  return new Promise<IRolesResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/system/role`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getScopes = (): Promise<IScopesResponse> => {
  return new Promise<IScopesResponse>(async (resolve, reject) => {
    try {
      const response = await get(`/api/system/role/scope-group`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const createRole = (role: IRoleObject): Promise<IRolesResponse> => {
  return new Promise<IRolesResponse>(async (resolve, reject) => {
    try {
      const { name, scope_groups, ou_ids } = role;
      const options = { name, scope_groups, ou_ids };
      const response = await post(`/api/system/role`, options);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateRole = (role: IRoleObject): Promise<IRolesResponse> => {
  return new Promise<IRolesResponse>(async (resolve, reject) => {
    try {
      const { name, role_id, scope_groups, ou_ids } = role;
      const options = { name, scope_groups, ou_ids };
      const response = await patch(`/api/system/role/${role_id}`, options);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteRole = (role_id: string): Promise<IRolesResponse> => {
  return new Promise<IRolesResponse>(async (resolve, reject) => {
    try {
      const response = await deleteRequest(`/api/system/role/${role_id}`);

      if (response.data.error || response.data.errors.length) {
        throw getErrorMessage(response.data);
      }

      resolve(response.data);
    } catch (error) {
      reject(error);
    }
  });
};

export default { getRoles };
