export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getValidationNumbers = (): number[] => {
  const first = getRandomInt(1, 10);
  const second = getRandomInt(1, 10);
  return [first, second];
};
