import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import logo from '../../assets/images/logo-ipnet.png';

const Logout = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const timeLogout = setTimeout(() => {
      history.push('/');
    }, 3000);

    return () => {
      clearTimeout(timeLogout);
    };
  });

  return (
    <main className="page-logout">
      <section>
        <img src={logo} alt="IPNET Growth Partner" />
        <p>
          <strong>{translate('disconnected_text')}</strong>
          {translate('redirecting_text')}
        </p>
      </section>
    </main>
  );
};

export default Logout;
