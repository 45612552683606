import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/hard-disk.svg';
import Topbar from './Topbar';

interface IProps {
  showSnackBar(message: string, type: string): void;
}

const Home = (props: IProps): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <section>
      <Topbar showSnackBar={props.showSnackBar} />
      <img src={logo} className="img-main" alt="Logo HD" />
      <h2 className="txt-main">{translate('home')}</h2>
    </section>
  );
};

export default Home;
