import React, { cloneElement, useState } from 'react';
import SnackBar from '../SnackBar';
import SideBar from './SideBar';
import TopBar from './TopBar';

interface IProps {
  children: JSX.Element;
}

const PanelLayout = (props: IProps): JSX.Element => {
  const { children } = props;
  const [showSidebar, setShowSidebar] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snackType, setSnackType] = useState('');
  const [snackMessage, setSnackMessage] = useState('');

  const toggleSidebar = () => {
    if (showSidebar) {
      setIsClosing(true);
      setIsOpening(false);
      setTimeout(() => {
        setShowSidebar(false);
      }, 400);
    } else {
      setIsClosing(false);
      setIsOpening(true);
      setShowSidebar(true);
    }
  };

  const showSnackBar = (message: string, type = 'success') => {
    setSnackMessage(message);
    setSnackType(type);
    setShowSnack(true);
    setTimeout(() => {
      setShowSnack(false);
    }, 4000);
  };

  const newChildren = cloneElement(props.children, { showSnackBar: showSnackBar });

  return (
    <>
      <TopBar toggleSidebar={toggleSidebar} />
      <SnackBar show={showSnack} message={snackMessage} type={snackType} />
      <main className="main-panel">
        {showSidebar && <SideBar isClosing={isClosing} isOpening={isOpening} />}
        <div className={`panel-content ${showSidebar && !isClosing ? 'with-sidebar' : ''}`}>{newChildren}</div>
      </main>
    </>
  );
};

export default PanelLayout;
